import { Box, Button, Grow, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { useIsVisible } from "./IsVisible";

export default function CarCard(props) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);
  const exploreUrl =
    props.car.brochure === "fronx.pdf"
      ? "https://shreyashautomotives.com/fronx"
      : `${process.env.REACT_APP_BACKEND_URL}brochures/${props.type}/${props.car.brochure}`;

  return (
    <>
      <div ref={ref}>
        <Grow timeout={1000} in={isVisible} container={ref.current}>
          <Stack
            bgcolor={"primary.white"}
            sx={[
              { maxWidth: 340 },
              {
                "&:hover": {
                  boxShadow: "0px 24px 48px -12px rgba(16, 24, 40, 0.18)",
                },
              },
            ]}
            boxShadow="1"
          >
            <Stack position={"relative"} overflow={"hidden"}>
              <Stack
                px={"5%"}
                position="absolute"
                sx={{ top: "5%" }}
                zIndex="100"
                bgcolor={"transparent"}
              ></Stack>
              <Box
                component={"img"}
                sx={[
                  {
                    height: 225,
                    objectFit: "contain",
                    position: "relative",
                    transition: "all .5s ease-in-out",
                  },
                  { "&:hover": { transform: "scale(1.1)" } },
                ]}
                src={`${process.env.REACT_APP_BACKEND_URL}images/${props.type}/${props.car.image}`}
                title={props.car.name}
                alt={props.car.name}
              />
            </Stack>

            <Stack
              direction={"row"}
              px="5%"
              py="3%"
              display="flex"
              justifyContent={"space-between"}
            >
              <Box
                component={"img"}
                style={{
                  width: "130px",
                  maxHeight: "40px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                alt="nexa"
                src={`${process.env.REACT_APP_BACKEND_URL}images/${props.type}/logos/${props.car.image}`}
              />

              <Typography
                display={"flex"}
                maxWidth="30%"
                textAlign={"end"}
                justifyContent="flex-end"
                sx={{ typography: "textSmSemi", cursor: "pointer" }}
                color="text.secondary"
              >
                <a
                  href={`${process.env.REACT_APP_BACKEND_URL}brochures/${props.type}/${props.car.brochure}`}
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  <DownloadIcon /> Download Brochure
                </a>
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              px="5%"
              pb={"5%"}
              display="flex"
              justifyContent={"space-between"}
            >
              <Button
                size="small"
                fullWidth
                variant="contain"
                onClick={() =>
                  props.bookNowRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  })
                }
                sx={[
                  {
                    color: "primary.black",
                    my: "10px",
                    mr: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.white",
                      backgroundColor: "primary.black",
                    },
                  },
                ]}
              >
                Book Now
              </Button>

              <Button
                fullWidth
                href={exploreUrl}
                target="_blank"
                rel="noreferrer"
                size="small"
                variant="contain"
                sx={[
                  {
                    color: "primary.white",
                    backgroundColor: "primary.black",
                    my: "10px",
                    ml: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.black",
                      backgroundColor: "primary.white",
                    },
                  },
                ]}
              >
                Explore
              </Button>
            </Stack>
          </Stack>
        </Grow>
      </div>
    </>
  );
}
