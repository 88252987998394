import React from "react";
import Navbar from "../Components/Navbar";
import Form from "../Components/Form";
import Footer from "../Components/Footer";
import { useLocation } from "react-router-dom";

const RootLayout = (props) => {
  const location = useLocation();

  return (
    <>
      <Navbar navbarRef={props.bookNowRef} nav2={props.nav2} />
      {props.children}
      {location.pathname.split("/")[1] === "arena" ||
      location.pathname.split("/")[1] === "nexa" ||
      location.pathname.split("/")[1] === "car-page" ||
      location.pathname.split("/")[1] === "" ? (
        <Form formRef={props.bookNowRef} />
      ) : null}
      <Footer />
    </>
  );
};

export default RootLayout;
