import { Stack } from "@mui/system";
import React from "react";
import HomeHoverPlay from "../Components/HomeHoverPlay";
import MainCarousel from "../Components/MainCarousel";
// import { Skeleton } from "@mui/material";

export default function Home() {
  return (
    <Stack bgcolor={"black"}>
      {<MainCarousel />}
      {/* {isLoading && <Skeleton />} */}
      <Stack my={4} bgcolor={"black"} py={6}>
        <HomeHoverPlay />
      </Stack>
    </Stack>
  );
}
