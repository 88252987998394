import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = (props) => {
  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      ref={props.captchaRef}
      name={props.name}
      onChange={(value) => {
        props.handleChange(value);
        props.setFieldValue("token", value);
      }}
    />
  );
};

export default Recaptcha;
