import React from "react";
import "../assets/css/hoverPlay.css";
export default function HoverPlay() {
  return (
    <div>
      <ul className="accordion_ul">
        <li className="accordion_li widthHover">
          <div className="bg-image">
            <a href={"/car-page"} className="img-gradient">
              <img
                data-src="//nexaprod4.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-10c_engine.webp?modified=20230109150429"
                className="accordion_img"
                alt="nexa"
                width="811"
                height="629"
                src="//nexaprod4.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-10c_engine.webp?modified=20230109150429"
              />
            </a>
          </div>
          <div className="pv-content">
            <h2>1.0L Turbo Boosterjet Engine</h2>
            <p>Shaping exhilarating drives.</p>
          </div>
        </li>
        <li className="accordion_li widthHover">
          <div className="bg-image img-gradient">
            <a href={"/car-page"} className="img-gradient">
              <img
                data-src="//nexaprod5.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-12k_engine.webp?modified=20230109150501"
                className="accordion_img"
                alt="accordion"
                width="811"
                height="629"
                src="//nexaprod5.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-12k_engine.webp?modified=20230109150501"
              />
            </a>
          </div>
          <div className="pv-content">
            <h2>Advanced 1.2L K-Series Dual Jet, Dual VVT Engine</h2>
            <p>Forged for new age performance.</p>
          </div>
        </li>
        <li className="accordion_li widthHover">
          <div className="bg-image">
            <a href={"/car-page"} className="img-gradient">
              <img
                data-src="//nexaprod6.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-smart_hybird.webp?modified=20230109150531"
                className="accordion_img"
                alt="nexa-second"
                width="811"
                height="629"
                src="//nexaprod6.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-smart_hybird.webp?modified=20230109150531"
              />
            </a>
          </div>
          <div className="pv-content">
            <h2>Smart Hybrid Technology</h2>
            <p>Where smartness and efficiency take shape.</p>
          </div>
        </li>
        <li className="accordion_li widthHover">
          <div className="bg-image">
            <a href={"/car-page"} className="img-gradient">
              <img
                data-src="//nexaprod1.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-amt.webp?modified=20230109150631"
                className="accordion_img"
                alt="nexa-product"
                width="811"
                height="629"
                src="//nexaprod1.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-amt.webp?modified=20230109150631"
              />
            </a>
          </div>
          <div className="pv-content">
            <h2>6-speed Automatic Transmission with Paddle Shifters</h2>
            <p>Go through the gears in a new way.</p>
          </div>
        </li>
        <li className="accordion_li widthHover">
          <div className="bg-image">
            <a href={"/car-page"} className="img-gradient">
              <img
                data-src="//nexaprod2.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-ags.webp?modified=20230111094946"
                className="accordion_img"
                alt="alexa-prod-another"
                width="811"
                height="629"
                src="//nexaprod2.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-ags.webp?modified=20230111094946"
              />
            </a>
          </div>
          <div className="pv-content">
            <h2>Auto Gear Shift</h2>
            <p>Shaped for comfort and convenience.</p>
          </div>
        </li>
      </ul>
    </div>
  );
}
