const banners = [
  {
    id: 1,
    type: "image",
    name: `${process.env.REACT_APP_BACKEND_URL}images/banner/fronx.webp`,
  },
  {
    id: 2,
    type: "video",
    name: `${process.env.REACT_APP_BACKEND_URL}videos/arena.mov`,
    // name: `https://shreyashautomotive.s3.ap-south-1.amazonaws.com/videos/arena.mov`,
  },
  {
    id: 3,
    type: "image",
    name: `${process.env.REACT_APP_BACKEND_URL}images/banner/baleno.webp`,
  },
  {
    id: 4,
    type: "video",
    name: `${process.env.REACT_APP_BACKEND_URL}videos/nexa.mov`,
  },
  {
    id: 5,
    type: "image",
    name: `${process.env.REACT_APP_BACKEND_URL}images/banner/jimny.webp`,
  },
  {
    id: 6,
    type: "video",
    name: `${process.env.REACT_APP_BACKEND_URL}videos/technology.mov`,
  },
];

export default banners;
