import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import fbLogo from "../../assets/images/FacebookLogo.webp";
import mapLogo from "../../assets/images/GoogleLogo.png";
// import whatsLogo from "../../assets/images/whatsappLogo.png";
const ClientCards = (props) => {
  return (
    <Stack>
      <Card elevation={false}>
        <CardContent>
          <Typography
            py={2}
            sx={{ typography: { xs: "textLgSemi", md: "textXlSemi" } }}
          >
            {props.Mainheading}
          </Typography>
          <Stack display={"flex"} direction={"row"} mb={2}>
            <a
              href={props.facebookLink}
              target="_blank"
              rel="noreferrer"
              style={{ all: "unset" }}
            >
              <Box
                component={"img"}
                sx={[
                  {
                    cursor: "pointer",
                    filter: "grayscale(90%)",
                  },
                  {
                    "&:hover": { filter: "grayscale(0%)" },
                    transition: "ease-in-out 0.4s",
                  },
                ]}
                width={50}
                src={fbLogo}
              />
            </a>
            <a
              href={props.googleLink}
              target="_blank"
              rel="noreferrer"
              style={{ all: "unset" }}
            >
              <Box
                component={"img"}
                sx={[
                  {
                    cursor: "pointer",
                    filter: "grayscale(90%)",
                  },
                  {
                    "&:hover": {
                      filter: "grayscale(0%)",
                      transition: "ease-in-out 0.4s",
                    },
                  },
                ]}
                width={50}
                mx={1}
                src={mapLogo}
              />
            </a>
            {/* <a
              href={props.googleLink}
              target="_blank"
              rel="noreferrer"
              style={{ all: "unset" }}
            >
              <Box
                component={"img"}
                sx={[
                  {
                    cursor: "pointer",
                    filter: "grayscale(90%)",
                  },
                  {
                    "&:hover": {
                      filter: "grayscale(0%)",
                      transition: "ease-in-out 0.4s",
                    },
                  },
                ]}
                width={55}
                src={whatsLogo}
              />
            </a> */}
          </Stack>

          <Typography sx={{ typography: { xs: "textLgReg", md: "textLgReg" } }}>
            {props.Subheading}
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};

export default ClientCards;
