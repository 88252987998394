const AboutCardData = [
  {
    facebookLink:
      "https://www.facebook.com/MarutiSuzukiArenaShreyashAutomotivesGTKarnalRoad/",
    googleLink: "https://goo.gl/maps/G9mCnQWptacVbHwH6",
    Mainheading: "Maruti Suzuki ARENA 3S",
    Subheading:
      "SSI Industrial Area, Sector 9, Jahangirpuri, New Delhi, Delhi 110033 TEL.: 011-43288888",
  },
  {
    facebookLink:
      "https://www.facebook.com/marutisuzukiarenaShreyashAutomotivesharkrishanmarg/",
    googleLink: "https://goo.gl/maps/Ma1M1wWytLNLLTZS7",
    Mainheading: "Maruti Suzuki ARENA",
    Subheading:
      "SSG Majesty Mall, Plot No. 2 Road No. 43, Guru Harkishan Marg, Pitam Pura, New Delhi, Delhi 110034 TEL : 011-40777444",
  },
  {
    facebookLink:
      "https://www.facebook.com/NexaShreyashAutomotivesmayapuridelhi/",
    googleLink: "https://goo.gl/maps/VQAnJVJm5tRbTgRm8",
    Mainheading: "NEXA",
    Subheading:
      "A-23, Satguru Ram Singh Rd, Mayapuri Industrial Area Phase Il, Mayapuri, New Delhi, Delhi 110064 MOB.: 9999673652",
  },
  {
    facebookLink:
      "https://www.facebook.com/NexaShreyashAutomotivesPitampuraDelhi/",
    googleLink: "https://goo.gl/maps/kjLsAyoRr4wXWKs19",
    Mainheading: "NEXA",
    Subheading:
      "SSG Majesty Mall, Plot no.1, Road No.43, Guru Harkishan Marg, Pitam Pura, Delhi, 110002 MOB.: 7838555047",
  },
];
export default AboutCardData;
