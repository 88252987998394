import React, { lazy, Suspense, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { useFormik } from "formik";
import "yup-phone";
import * as yup from "yup";
import drivingschool from "../../assets/images/drivingschool.webp";
import useApi from "../../hooks/use-api";
import Recaptcha from "../Recaptcha";
import Response from "../../Utils/Response";

const BannerImage = lazy(() => import("../BannerImage"));
export default function DrivingSchool() {
  const captchaRef = useRef(null);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  const validationSchema = yup.object({
    mobile: yup
      .string()
      .phone(null, true, "Invalid Number")
      .required("Your mobile number is required"),
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your full name."),
    disclaimer: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      otp: "",
      disclaimer: false,
      checkField: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await useApi.postRequest(
          `driving-schools`,
          false,
          values
        );
        if (result.status) {
          captchaRef.current.reset();
          formik.resetForm();
          return setIsResponse({
            status: true,
            type: "success",
            message: result.message,
            subText:
              "Thank you for sharing your details. We will be in touch soon.",
            buttonText: "Back",
          });
        }
        throw new Error(result.message);
      } catch (error) {
        setIsResponse({
          status: true,
          type: "failed",
          message: error.message,
          subText: "Please Try Again.",
          buttonText: "Try Again",
        });
      }
    },
  });

  return (
    <Stack>
      <Suspense
        fallback={
          <Skeleton sx={{ mx: "20px" }} variant="rectangular" height={730} />
        }
      >
        <BannerImage value={drivingschool} />
      </Suspense>
      <Stack my={"3%"} mx={"3%"} bgcolor={"primary.white"}>
        <Typography
          textAlign={"center"}
          sx={{ typography: { xs: "displayXsSemi", md: "displaySmSemi" } }}
        >
          REGISTER HERE
        </Typography>
        {!isResponse.status && (
          <form onSubmit={formik.handleSubmit}>
            <Stack
              spacing={"15px"}
              direction={"column"}
              justifyContent="center"
              display={"flex"}
              alignItems="center"
            >
              <TextField
                fullWidth
                sx={{
                  maxWidth: "sm",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="name"
                variant="standard"
                label="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />

              <TextField
                fullWidth
                sx={{
                  maxWidth: "sm",
                  ".MuiFormHelperText-root": {
                    typography: "textSmReg",
                    color: "#d3302f",
                  },
                }}
                color="secondary"
                size="medium"
                name="mobile"
                inputProps={{ maxLength: 10 }}
                variant="standard"
                label="Phone/mobile"
                value={formik.values.mobile}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                helperText={formik.touched.mobile && formik.errors.mobile}
              />
              <Stack
                display={"flex"}
                alignItems="flex-start"
                justifyContent={"start"}
              >
                <FormControlLabel
                  sx={{
                    pt: 2,
                    maxWidth: "sm",
                    ".MuiFormControlLabel-label": {
                      typography: "textXsReg",
                      color: "primary.gray600",
                    },
                  }}
                  control={
                    <Checkbox
                      sx={{
                        ".MuiCheckbox-root": { verticalAlign: "top" },
                      }}
                      size="small"
                      color="secondary"
                      checked={formik.disclaimer}
                    />
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.disclaimer &&
                    Boolean(formik.errors.disclaimer)
                  }
                  helperText={
                    formik.touched.disclaimer && formik.errors.disclaimer
                  }
                  label="Disclaimer: I agree that by clicking the ‘Book Now’ button below, I am explicitly soliciting a call from Maruti Suzuki India Ltd. Or its partners on my ‘Mobile’."
                  name="disclaimer"
                />

                {formik.errors.disclaimer ? (
                  <Stack
                    display={"flex"}
                    alignItems="flex-start"
                    justifyContent={"start"}
                    pt={1}
                  >
                    <Typography
                      sx={{ typography: "textXsReg" }}
                      color="#D3302F"
                    >
                      {formik.errors.disclaimer}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
              <Recaptcha
                name="token"
                captchaRef={captchaRef}
                handleChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
              />
              <TextField
                name="checkField"
                sx={{ display: "none" }}
                onChange={formik.handleChange}
              />

              <Button
                size="small"
                variant="contain"
                type="submit"
                sx={[
                  {
                    color: "primary.white",
                    backgroundColor: "primary.black",
                    my: "10px",
                    ml: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.black",
                      backgroundColor: "primary.white",
                    },
                  },
                ]}
              >
                Submit
              </Button>
            </Stack>
          </form>
        )}

        {isResponse.status && (
          <Response response={isResponse} setIsResponse={setIsResponse} />
        )}
      </Stack>
    </Stack>
  );
}
