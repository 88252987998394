import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import Carousel from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";
import pdf1 from "../assets/images/Artboard 1@4x.png";
import pdf2 from "../assets/images/Artboard 2@4x.png";
import pdf3 from "../assets/images/Artboard 3@4x.png";
import pdf4 from "../assets/images/Artboard 4@4x.png";
import pdf5 from "../assets/images/Artboard 5@4x.png";
import pdf6 from "../assets/images/Artboard 6@4x.png";
import pdf7 from "../assets/images/Artboard 7@4x.png";
import pdf8 from "../assets/images/Artboard 8@4x.png";
import pdf9 from "../assets/images/Artboard 9@4x.png";
import pdf10 from "../assets/images/Artboard 10@4x.png";
import pdf11 from "../assets/images/Artboard 11@4x.png";
import pdf12 from "../assets/images/Artboard 12@4x.png";
import pdf13 from "../assets/images/Artboard 13@4x.png";
import pdf14 from "../assets/images/Artboard 14@4x.png";
import { Stack } from "@mui/system";
const pdfImg = [
  pdf1,
  pdf2,
  pdf3,
  pdf4,
  pdf5,
  pdf6,
  pdf7,
  pdf8,
  pdf9,
  pdf10,
  pdf11,
  pdf12,
  pdf13,
  pdf14,
];
export default function PdfCarousel() {
  return (
    <Stack display={"flex"} alignItems="center" justifyContent={"center"}>
      <Stack mt={5} maxWidth={"60%"}>
        <Carousel
          infiniteLoop={true}
          autoPlay={true}
          swipeable={true}
          thumbWidth={200}
        >
          {pdfImg.map((imgSrc) => {
            return (
              <div>
                <img src={imgSrc} alt="carousel" />
              </div>
            );
          })}
        </Carousel>
      </Stack>
    </Stack>
  );
}
