import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
const carouselImg = [
  {
    imgSrc:
      "https://nexaprod4.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-10c_engine.webp?modified=20230109150429",
    title: "1.0L Turbo Boosterjet Engine",
    supportText: "Shaping exhilarating drives.",
  },
  {
    imgSrc:
      "https://nexaprod5.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-12k_engine.webp?modified=20230109150501",
    title: "Advanced 1.2L K-Series Dual Jet, Dual VVT Engine",
    supportText: "Forged for new age performance.",
  },
  {
    imgSrc:
      "https://nexaprod2.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-ags.webp?modified=20230111094946",
    title: "Smart Hybrid Technology",
    supportText: "Where smartness and efficiency take shape.",
  },
  {
    imgSrc:
      "https://nexaprod6.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-smart_hybird.webp?modified=20230109150531",
    title: "6-speed Automatic Transmission with Paddle Shifters",
    supportText: "Go through the gears in a new way.",
  },
  {
    imgSrc:
      "https://nexaprod1.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/performance/performance-811x629-amt.webp?modified=20230109150631",
    title: "Auto Gear Shift",
    supportText: "Shaped for comfort and convenience.",
  },
];

export default function HoverCarousel() {
  return (
    <Stack
      display={"flex"}
      mx={3}
      direction={"row"}
      spacing={2}
      overflow="auto"
    >
      {carouselImg.map((data) => {
        return (
          <Stack>
            <Box
              component={"img"}
              maxWidth={"500px"}
              borderRadius={3}
              src={data.imgSrc}
              my={2}
            />
            <Typography
              sx={{
                typography: "textLgReg",
                textTransform: "uppercase",
                color: "white",
              }}
            >
              {data.title}
            </Typography>
            <Typography
              sx={{
                typography: "textMdReg",
                color: "white",
              }}
            >
              {data.supportText}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
