import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { Fragment } from "react";
import DragDropFile from "../../DragnDrop";
import Recaptcha from "../../Recaptcha";
import LinearProgressWithLabel from "../../LinearProgressWithLabel";

const Sell = (props) => {
  return (
    <Fragment>
      <Stack ref={props.formRef}></Stack>
      <Stack my={"3%"} mt="5%" mx={"3%"} bgcolor={"primary.white"}>
        <Typography
          textAlign={"center"}
          sx={{
            typography: { xs: "displayXsSemi", md: "displaySmSemi" },
            textTransform: "uppercase",
          }}
        >
          Enter your car details
        </Typography>
        <form onSubmit={props.formik.handleSubmit}>
          <Stack
            spacing={"15px"}
            direction={"column"}
            justifyContent="center"
            display={"flex"}
            alignItems="center"
          >
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="name"
              variant="standard"
              label="Name"
              value={props.formik.values.name}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.name && Boolean(props.formik.errors.name)
              }
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="email"
              variant="standard"
              label="Email"
              value={props.formik.values.email}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.email && Boolean(props.formik.errors.email)
              }
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            />
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="chassis_number"
              variant="standard"
              label="VIN/Chassis No."
              value={props.formik.values.chassis_number}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.chassis_number &&
                Boolean(props.formik.errors.chassis_number)
              }
              helperText={
                props.formik.touched.chassis_number &&
                props.formik.errors.chassis_number
              }
            />
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormHelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="mobile"
              inputProps={{ maxLength: 10 }}
              variant="standard"
              label="Phone/mobile"
              value={props.formik.values.mobile}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.mobile &&
                Boolean(props.formik.errors.mobile)
              }
              helperText={
                props.formik.touched.mobile && props.formik.errors.mobile
              }
            />
            {!props.isUploading && !props.isUploaded && (
              <DragDropFile
                onFileChangeHandler={props.onFileChangeHandler}
                isUploading={props.isUploading}
              />
            )}
            {props.isUploading && !props.isUploaded && (
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={props.percentage} />
              </Box>
            )}
            {props.isUploaded && !props.isUploading && (
              <DeleteIcon
                color="black"
                onClick={() => props.deleteFileHandler(props.fileName)}
                style={{ cursor: "pointer" }}
              />
            )}{" "}
            {props.fileName}
            <Stack
              display={"flex"}
              alignItems="flex-start"
              justifyContent={"start"}
            >
              <FormControlLabel
                sx={{
                  pt: 2,
                  maxWidth: "sm",
                  ".MuiFormControlLabel-label": {
                    typography: "textXsReg",
                    color: "primary.gray600",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      ".MuiCheckbox-root": { verticalAlign: "top" },
                    }}
                    size="small"
                    color="secondary"
                    checked={props.formik.disclaimer}
                  />
                }
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                error={
                  props.formik.touched.disclaimer &&
                  Boolean(props.formik.errors.disclaimer)
                }
                helperText={
                  props.formik.touched.disclaimer &&
                  props.formik.errors.disclaimer
                }
                label="Disclaimer: I agree that by clicking the ‘Submit’ button below, I am explicitly soliciting a call and message via whatsapp and any other medium from Maruti Suzuki India Ltd. or its partners on my ‘Mobile’."
                name="disclaimer"
              />

              {props.formik.errors.disclaimer ? (
                <Stack
                  display={"flex"}
                  alignItems="flex-start"
                  justifyContent={"start"}
                  pt={1}
                >
                  <Typography sx={{ typography: "textXsReg" }} color="#D3302F">
                    {props.formik.errors.disclaimer}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            <TextField
              name="checkField"
              sx={{ display: "none" }}
              onChange={props.formik.handleChange}
            />
            <Recaptcha
              name="token"
              captchaRef={props.captchaRef}
              handleChange={props.formik.handleChange}
              setFieldValue={props.formik.setFieldValue}
            />
            {!props.isRegistering && (
              <Button
                size="small"
                variant="contain"
                type="submit"
                sx={[
                  {
                    color: "primary.white",
                    backgroundColor: "primary.black",
                    my: "10px",
                    ml: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.black",
                      backgroundColor: "primary.white",
                    },
                  },
                ]}
              >
                Submit
              </Button>
            )}
            {props.isRegistering && <CircularProgress />}
          </Stack>
        </form>
      </Stack>
    </Fragment>
  );
};

export default Sell;
