import React from "react";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

import ClientsCardData from "../store/AboutCardData";
import ClientCards from "./Card/ClientCards";
// import Div from "../Layouts/Div";

const Journey = () => {
  return (
    <Stack
      py={2}
      sx={{ mb: "3rem" }}
      // bgcolor={"#f6f6f6"}
      maxWidth={"xl"}
    >
      <Grid container mx={8} spacing={{ md: "2rem", xl: "4rem" }}>
        {ClientsCardData.map((ClientValue, Index) => (
          <Grid Item lg={3}>
            <ClientCards
              googleLink={ClientValue.googleLink}
              facebookLink={ClientValue.facebookLink}
              Mainheading={ClientValue.Mainheading}
              Subheading={ClientValue.Subheading}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Journey;
