import React, { useState } from "react";
import { useFormik } from "formik";
import useApi from "../hooks/use-api";
import { buySchema } from "../schema";
import { useRef } from "react";
import Buy from "./Forms/TrueValue/Buy";
import Response from "../Utils/Response";

export default function BuyForm(props) {
  const captchaRef = useRef(null);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  // ? *************************************** Register the user ******************************** */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      car_id: "",
      disclaimer: false,
      checkField: "",
      token: "",
    },
    validationSchema: buySchema,
    onSubmit: async (values, action) => {
      try {
        props.setIsLoading(true);
        const result = await useApi.postRequest("buy-cars", false, values);
        props.setIsLoading(false);

        if (result.status) {
          action.resetForm();
          formik.resetForm();
          captchaRef.current.reset();
          return setIsResponse({
            status: true,
            type: "success",
            message: result.message,
            subText:
              "Thank you for sharing your details. We will be in touch soon.",
            buttonText: "Back",
          });
        }

        throw new Error(result.message);
      } catch (error) {
        setIsResponse({
          status: true,
          type: "failed",
          message: error.message,
          subText: "Please Try Again.",
          buttonText: "Try Again",
        });
      }
    },
  });

  return (
    <>
      {!isResponse.status && (
        <Buy
          formRef={props.formRef}
          formik={formik}
          cars={props.cars}
          captchaRef={captchaRef}
          isLoading={props.isLoading}
        />
      )}
      {isResponse.status && (
        <Response response={isResponse} setIsResponse={setIsResponse} />
      )}
    </>
  );
}
