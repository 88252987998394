import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import "../assets/css/mapStyle.css";
import aren from "../assets/images/markerPin.png";
import { Stack } from "@mui/system";
import useWindowDimensions from "./useWindowDimensions";
const mapData = [
  {
    lat: 28.6887947,
    pos: "pitamArena",
    lng: 77.107194,
  },
  {
    lat: 28.6887298,
    pos: "pitamNexa",
    lng: 77.1067234,
  },
  {
    lat: 28.7293796,
    pos: "jahangirArena",
    lng: 77.1596367,
  },
  {
    lat: 28.6245609,
    pos: "mayapuriNexa",
    lng: 77.1183325,
  },
];

function MapComponent() {
  const { height, width } = useWindowDimensions();
  const containerStyle = {
    width: width - 150,
    height: height - 250,
    borderRadius: "20px",

    margin: "40px",

    overflow: "hidden",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA2vWVDbM_1w74R2qJB8PE1xL5DSABPgf8",
  });

  const [map, setMap] = React.useState(null);
  const zoom = 11.9;
  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  console.log(map);
  return isLoaded ? (
    <Stack
      sx={{ borderRadius: 10 }}
      display={"flex"}
      maxWidth={"xl"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{
          lat: 28.6782544,
          lng: 77.1705845,
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {mapData.map((data) => {
          return (
            <>
              <Marker
                icon={{
                  url: aren,
                  scaledSize: new window.google.maps.Size(40, 55),
                }}
                animation={window.google.maps.Animation.BOUNCE}
                position={data}
              />
            </>
          );
        })}
      </GoogleMap>
    </Stack>
  ) : (
    <></>
  );
}

export default React.memo(MapComponent);
