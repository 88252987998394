import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { Fragment } from "react";
import Recaptcha from "../Recaptcha";

const Register = (props) => {
  return (
    <Fragment>
      <Stack ref={props.formRef}></Stack>
      <Stack my={"3%"} mt="5%" mx={"3%"} bgcolor={"primary.white"}>
        <Typography
          textAlign={"center"}
          sx={{
            typography: { xs: "displayXsSemi", md: "displaySmSemi" },
            textTransform: "uppercase",
          }}
        >
          REGISTER TO GET Exciting Offers
        </Typography>
        <form onSubmit={props.formik.handleSubmit}>
          <Stack
            spacing={"15px"}
            direction={"column"}
            justifyContent="center"
            display={"flex"}
            alignItems="center"
          >
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormhelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="name"
              variant="standard"
              label="Name *"
              value={props.formik.values.name}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.name && Boolean(props.formik.errors.name)
              }
              helperText={props.formik.touched.name && props.formik.errors.name}
            />
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormhelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="email"
              variant="standard"
              label="Email *"
              value={props.formik.values.email}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.email && Boolean(props.formik.errors.email)
              }
              helperText={
                props.formik.touched.email && props.formik.errors.email
              }
            />
            <TextField
              fullWidth
              sx={{
                maxWidth: "sm",
                ".MuiFormhelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="mobile"
              inputProps={{ maxLength: 10 }}
              variant="standard"
              label="Phone/mobile *"
              value={props.formik.values.mobile}
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.mobile &&
                Boolean(props.formik.errors.mobile)
              }
              helperText={
                props.formik.touched.mobile && props.formik.errors.mobile
              }
            />
            <TextField
              fullWidth
              select
              sx={{
                maxWidth: "sm",
                ".MuiFormhelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="car_id"
              variant="standard"
              label="Car Model *"
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.car_id &&
                Boolean(props.formik.errors.car_id)
              }
              helperText={
                props.formik.touched.car_id && props.formik.errors.car_id
              }
              value={props.formik.car_id}
              onChange={props.formik.handleChange}
            >
              <MenuItem value="" disabled selected>
                Select Cart Model
              </MenuItem>
              {props.cars.map((car) => (
                <MenuItem key={car.id} value={car.id}>
                  {car.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              fullWidth
              select
              sx={{
                maxWidth: "sm",
                ".MuiFormhelperText-root": {
                  typography: "textSmReg",
                  color: "#d3302f",
                },
              }}
              color="secondary"
              size="medium"
              name="buyer_type"
              variant="standard"
              label="Buyer Type *"
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.buyer_type &&
                Boolean(props.formik.errors.buyer_type)
              }
              helperText={
                props.formik.touched.buyer_type &&
                props.formik.errors.buyer_type
              }
              defaultValue={props.formik.buyer_type}
              onChange={props.formik.handleChange}
            >
              <MenuItem value="" disabled selected>
                Select Your Buyer Type
              </MenuItem>
              <MenuItem value={"My First Car"}>My First Car</MenuItem>
              <MenuItem value={"Additional Car"}>Additional Car</MenuItem>
              <MenuItem value={"Exchange Of An Old Car"}>
                Exchange Of An Old Car
              </MenuItem>
            </TextField>
            <Stack
              display={"flex"}
              alignItems="flex-start"
              justifyContent={"start"}
            >
              <FormControlLabel
                sx={{
                  pt: 2,
                  maxWidth: "sm",
                  ".MuiFormControlLabel-label": {
                    typography: "textXsReg",
                    color: "primary.gray600",
                  },
                }}
                control={
                  <Checkbox
                    sx={{
                      ".MuiCheckbox-root": { verticalAlign: "top" },
                    }}
                    size="small"
                    color="secondary"
                    checked={props.formik.disclaimer}
                  />
                }
                onChange={props.formik.handleChange}
                onBlur={props.formik.handleBlur}
                error={
                  props.formik.touched.disclaimer &&
                  Boolean(props.formik.errors.disclaimer)
                }
                helperText={
                  props.formik.touched.disclaimer &&
                  props.formik.errors.disclaimer
                }
                label="Disclaimer: I agree that by clicking the ‘Submit’ button below, I am explicitly soliciting a call and message via whatsapp and any other medium from Maruti Suzuki India Ltd. or its partners on my ‘Mobile’."
                name="disclaimer"
              />

              {props.formik.errors.disclaimer ? (
                <Stack
                  display={"flex"}
                  alignItems="flex-start"
                  justifyContent={"start"}
                  pt={1}
                >
                  <Typography sx={{ typography: "textXsReg" }} color="#D3302F">
                    {props.formik.errors.disclaimer}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>
            <Recaptcha
              name="token"
              captchaRef={props.captchaRef}
              handleChange={props.formik.handleChange}
              setFieldValue={props.formik.setFieldValue}
            />
            <TextField
              name="checkField"
              sx={{ display: "none" }}
              onChange={props.formik.handleChange}
            />
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                size="small"
                variant="contain"
                type="submit"
                disabled={props.isRegistering}
                sx={[
                  {
                    color: "primary.white",
                    backgroundColor: props.isRegistering
                      ? "gainsboro"
                      : "primary.black",
                    my: "10px",
                    ml: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.black",
                      backgroundColor: "primary.white",
                    },
                  },
                ]}
              >
                Submit
              </Button>
              {props.isRegistering && (
                <CircularProgress
                  color="secondary"
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Stack>
        </form>
      </Stack>
    </Fragment>
  );
};

export default Register;
