import { Stack } from "@mui/material";
import React from "react";
import "./privacy.css";

export default function Privacy() {
  return (
    <>
      <Stack mx={"3%"} my={"2%"}>
        <div data-custom-class="body">
          <div>
            <strong>
              <span style={{ fontSize: "26px" }}>
                <span data-custom-class="title">PRIVACY NOTICE</span>
              </span>
            </strong>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <strong>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="subtitle">
                    Last updated{" "}
                    <bdt className="question">February 06, 2023</bdt>
                  </span>
                </span>
              </strong>
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  This privacy notice for{" "}
                  <bdt className="question">
                    Shreyash Automotives
                    <bdt className="block-component" />
                  </bdt>{" "}
                  (<bdt className="block-component" />"
                  <bdt className="block-component" />
                  <strong>Company</strong>
                  <bdt className="statement-end-if-in-editor" />
                  ," "<strong>we</strong>," "<strong>us</strong>," or "
                  <strong>our</strong>"
                  <bdt className="statement-end-if-in-editor" />
                </span>
                <span data-custom-class="body_text">
                  ), describes how and why we might collect, store, use, and/or
                  share (<bdt className="block-component" />"
                  <strong>process</strong>"
                  <bdt className="statement-end-if-in-editor" />) your
                  information when you use our services (
                  <bdt className="block-component" />"<strong>Services</strong>"
                  <bdt className="statement-end-if-in-editor" />
                  ), such as when you:
                </span>
              </span>
            </span>
            <span style={{ fontSize: "15px" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Visit our website
                    <bdt className="block-component" /> at{" "}
                    <bdt className="question">
                      <a
                        href="https://shreyashautomotives.com"
                        target="_blank"
                        data-custom-class="link"
                        rel="noreferrer"
                      >
                        https://shreyashautomotives.com
                      </a>
                    </bdt>
                    <span style={{ fontSize: "15px" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: "15px" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <bdt className="statement-end-if-in-editor">
                                , or any website of ours that links to this
                                privacy notice
                              </bdt>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <bdt className="block-component">
              <span style={{ fontSize: "15px" }}>
                <span style={{ fontSize: "15px" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </bdt>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <strong>Questions or concerns? </strong>Reading this privacy
                    notice will help you understand your privacy rights and
                    choices. If you do not agree with our policies and
                    practices, please do not use our Services. If you still have
                    any questions or concerns, please contact us at{" "}
                    <bdt className="question">info@shreyashautomotives.com</bdt>
                    .
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <strong>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="heading_1">
                    SUMMARY OF KEY POINTS
                  </span>
                </span>
              </strong>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>
                      This summary provides key points from our privacy notice,
                      but you can find out more details about any of these
                      topics by clicking the link following each key point or by
                      using our table of contents below to find the section you
                      are looking for. You can also click{" "}
                    </em>
                  </strong>
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>here</em>
                    </strong>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em> to go directly to our table of contents.</em>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>What personal information do we process?</strong> When
                  you visit, use, or navigate our Services, we may process
                  personal information depending on how you interact with{" "}
                  <bdt className="block-component"></bdt>
                  <bdt className="question">Shreyash Automotives</bdt>
                  <bdt className="statement-end-if-in-editor" /> and the
                  Services, the choices you make, and the products and features
                  you use. Click
                </span>
              </span>
              <a data-custom-class="link" href="#personalinfo">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text"> to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we process any sensitive personal information?
                  </strong>
                  <bdt className="block-component" />
                  We do not process sensitive personal information.
                  <bdt className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we receive any information from third parties?
                  </strong>
                  <bdt className="block-component" />
                  We do not receive any information from third parties.
                  <bdt className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do we process your information?</strong> We
                  process your information to provide, improve, and administer
                  our Services, communicate with you, for security and fraud
                  prevention, and to comply with law. We may also process your
                  information for other purposes with your consent. We process
                  your information only when we have a valid legal reason to do
                  so. Click{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#infouse">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text"> to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>
                    In what situations and with which{" "}
                    <bdt className="block-component" />
                    parties do we share personal information?
                  </strong>{" "}
                  We may share information in specific situations and with
                  specific <bdt className="block-component" />
                  third parties. Click{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#whoshare">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  {" "}
                  to learn more.
                  <bdt className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do we keep your information safe?</strong> We have{" "}
                  <bdt className="block-component" />
                  organizational
                  <bdt className="statement-end-if-in-editor" /> and technical
                  processes and procedures in place to protect your personal
                  information. However, no electronic transmission over the
                  internet or information storage technology can be guaranteed
                  to be 100% secure, so we cannot promise or guarantee that
                  hackers, cybercriminals, or other{" "}
                  <bdt className="block-component" />
                  unauthorized
                  <bdt className="statement-end-if-in-editor" /> third parties
                  will not be able to defeat our security and improperly
                  collect, access, steal, or modify your information. Click{" "}
                </span>
              </span>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  {" "}
                  to learn more.
                  <bdt className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>What are your rights?</strong> Depending on where you
                  are located geographically, the applicable privacy law may
                  mean you have certain rights regarding your personal
                  information. Click
                </span>
              </span>
              <a data-custom-class="link" href="#privacyrights">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text"> to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>How do you exercise your rights?</strong> The easiest
                  way to exercise your rights is by filling out our data subject
                  request form available <bdt className="block-component" />
                </span>
              </span>
              <a
                data-custom-class="link"
                href="https://app.termly.io/notify/f3e40d43-c747-4353-bfa5-3a8c11b43c12"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />, or by contacting us. We
                  will consider and act upon any request in accordance with
                  applicable data protection laws.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  Want to learn more about what{" "}
                  <bdt className="block-component" />
                  <bdt className="question">Shreyash Automotives</bdt>
                  <bdt className="statement-end-if-in-editor" /> does with any
                  information we collect? Click
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  {" "}
                  to review the notice in full.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="toc" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        TABLE OF CONTENTS
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infocollect">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    <bdt className="block-component" />
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>{" "}
                  </span>{" "}
                </span>{" "}
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#cookies">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className="block-component" />
                          </span>
                        </span>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#inforetain">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#infosafe">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#privacyrights">
                    7. WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#DNT">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    8. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#caresidents">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </a>
              </span>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <a data-custom-class="link" href="#policyupdates">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    10. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#contact">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#request">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="infocollect" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            1. WHAT INFORMATION DO WE COLLECT?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="personalinfo" style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: "15px" }}>
                  <strong>Personal information you disclose to us</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em> </em>
                            </strong>
                            <em>
                              We collect personal information that you provide
                              to us.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We collect personal information that you voluntarily provide
                    to us when you{" "}
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    express an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>Personal Information Provided by You.</strong> The
                    personal information that we collect depends on the context
                    of your interactions with us and the Services, the choices
                    you make, and the products and features you use. The
                    personal information we collect may include the following:
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">names</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">phone numbers</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: "15px" }}>
                        <span data-custom-class="body_text">
                          <bdt className="question">email addresses</bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                      </span>
                      <span data-custom-class="body_text">
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <strong>Sensitive Information.</strong>
                  <bdt className="block-component" />
                  We do not process sensitive information.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px" }}>
                <span data-custom-class="body_text">
                  <bdt className="else-block" />
                </span>
              </span>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <bdt className="block-component">
                          <bdt className="block-component" />
                        </bdt>
                      </span>
                    </span>
                  </span>
                </span>
                <bdt className="block-component"></bdt>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: "15px" }}>
                  <strong>Information automatically collected</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em> </em>
                            </strong>
                            <em>
                              Some information — such as your Internet Protocol
                              (IP) address and/or browser and device
                              characteristics — is collected automatically when
                              you visit our Services.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.{" "}
                    <bdt className="block-component" />
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <span data-custom-class="body_text" />
                    </bdt>
                  </span>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The information we collect includes:
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Log and Usage Data.</em> Log and usage data is
                      service-related, diagnostic, usage, and performance
                      information our servers automatically collect when you
                      access or use our Services and which we record in log
                      files. Depending on how you interact with us, this log
                      data may include your IP address, device information,
                      browser type, and settings and information about your
                      activity in the Services
                      <span style={{ fontSize: "15px" }}> </span>(such as the
                      date/time stamps associated with your usage, pages and
                      files viewed, searches, and other actions you take such as
                      which features you use), device event information (such as
                      system activity, error reports (sometimes called{" "}
                      <bdt className="block-component" />
                      "crash dumps"
                      <bdt className="statement-end-if-in-editor" />
                      ), and hardware settings).
                      <span style={{ fontSize: "15px" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: "15px" }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: "15px" }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span data-custom-class="body_text">
                        <em>Location Data.</em> We collect location data such as
                        information about your device's location, which can be
                        either precise or imprecise. How much information we
                        collect depends on the type and settings of the device
                        you use to access the Services. For example, we may use
                        GPS and other technologies to collect geolocation data
                        that tells us your current location (based on your IP
                        address). You can opt out of allowing us to collect this
                        information either by refusing access to the information
                        or by disabling your Location setting on your device.
                        However, if you choose to opt out, you may not be able
                        to use certain aspects of the Services.
                        <span style={{ fontSize: "15px" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div>
                <bdt className="block-component">
                  <span style={{ fontSize: "15px" }} />
                </bdt>
                <bdt className="statement-end-if-in-editor" />
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span data-custom-class="body_text">
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                      >
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor">
                            <bdt className="block-component" />
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <bdt className="block-component" />
                  </span>
                </span>
              </div>
              <div id="infouse" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              2. HOW DO WE PROCESS YOUR INFORMATION?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span
                      style={{ color: "rgb(89, 89, 89)", fontSize: "15px" }}
                    >
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span data-custom-class="body_text">
                              <strong>
                                <em>In Short: </em>
                              </strong>
                              <em>
                                We process your information to provide, improve,
                                and administer our Services, communicate with
                                you, for security and fraud prevention, and to
                                comply with law. We may also process your
                                information for other purposes with your
                                consent.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        We process your personal information for a variety of
                        reasons, depending on how you interact with our
                        Services, including:
                      </strong>
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                </div>
                <ul>
                  <li style={{ lineHeight: "1.5" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span data-custom-class="body_text">
                          <strong>
                            To deliver and facilitate delivery of services to
                            the user.{" "}
                          </strong>
                          We may process your information to provide you with
                          the requested service.
                          <span
                            style={{
                              fontSize: "15px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span data-custom-class="body_text">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <span style={{ fontSize: "15px" }}>
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span style={{ fontSize: "15px" }}>
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span data-custom-class="body_text">
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </div>
                  <ul>
                    <li style={{ lineHeight: "1.5" }}>
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span
                          style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              To respond to user inquiries/offer support to
                              users.{" "}
                            </strong>
                            We may process your information to respond to your
                            inquiries and solve any potential issues you might
                            have with the requested service.
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: "15px" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: "15px" }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="statement-end-if-in-editor"></bdt>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </li>
                  </ul>
                  <div style={{ lineHeight: "1.5" }}>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <span
                        style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                      >
                        <span data-custom-class="body_text">
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: "15px" }} />
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: "15px" }} />
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: "15px" }} />
                          </bdt>
                        </div>
                        <ul>
                          <li style={{ lineHeight: "1.5" }}>
                            <span
                              style={{
                                fontSize: "15px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <strong>
                                    To <bdt className="block-component" />
                                    fulfill
                                    <bdt className="statement-end-if-in-editor" />{" "}
                                    and manage your orders.{" "}
                                  </strong>
                                  We may process your information to{" "}
                                  <bdt className="block-component" />
                                  fulfill
                                  <bdt className="statement-end-if-in-editor" />{" "}
                                  and manage your orders, payments, returns, and
                                  exchanges made through the Services.
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "15px",
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: "15px" }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </bdt>
                          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </p>
                          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </p>
                          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                          </p>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: "15px" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>To request feedback.</strong>We
                                      may process your information when
                                      necessary to request feedback and to
                                      contact you about your use of our
                                      Services.
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor"></bdt>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <p style={{ fontSize: "15px", lineHeight: "1.5" }}>
                            <bdt className="block-component" />
                          </p>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                          </div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <strong>
                                      To send you marketing and promotional
                                      communications.{" "}
                                    </strong>
                                    We may process the personal information you
                                    send to us for our marketing purposes, if
                                    this is in accordance with your marketing
                                    preferences. You can opt out of our
                                    marketing emails at any time. For more
                                    information, see{" "}
                                    <bdt className="block-component" />"
                                    <bdt className="statement-end-if-in-editor" />
                                  </span>
                                </span>
                              </span>
                              <a data-custom-class="link" href="#privacyrights">
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      WHAT ARE YOUR PRIVACY RIGHTS?
                                    </span>
                                  </span>
                                </span>
                              </a>
                              <span
                                style={{
                                  fontSize: "15px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <bdt className="block-component" />"
                                    <bdt className="statement-end-if-in-editor" />{" "}
                                    below).
                                    <span
                                      style={{
                                        fontSize: "15px",
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: "15px",
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: "15px" }}>
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: "15px" }} />
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: "15px" }} />
                              </bdt>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: "15px",
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To deliver targeted advertising to you.{" "}
                                      </strong>
                                      We may process your information to develop
                                      and display{" "}
                                      <bdt className="block-component" />
                                      personalized
                                      <bdt className="statement-end-if-in-editor" />{" "}
                                      content and advertising tailored to your
                                      interests, location, and more.
                                      <bdt className="block-component" />
                                    </span>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="block-component">
                                  <span data-custom-class="body_text" />
                                </bdt>
                              </span>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text">
                                      <strong>To post testimonials.</strong> We
                                      post testimonials on our Services that may
                                      contain personal information.
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </div>
                              <ul>
                                <li style={{ lineHeight: "1.5" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: "15px" }}>
                                      <strong>To protect our Services.</strong>{" "}
                                      We may process your information as part of
                                      our efforts to keep our Services safe and
                                      secure, including fraud monitoring and
                                      prevention.
                                    </span>
                                  </span>
                                  <bdt className="statement-end-if-in-editor">
                                    <span style={{ fontSize: "15px" }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                </li>
                              </ul>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: "15px" }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: "15px" }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: "15px" }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: "15px" }}>
                                          <strong>
                                            To evaluate and improve our
                                            Services, products, marketing, and
                                            your experience.
                                          </strong>{" "}
                                          We may process your information when
                                          we believe it is necessary to identify
                                          usage trends, determine the
                                          effectiveness of our promotional
                                          campaigns, and to evaluate and improve
                                          our Services, products, marketing, and
                                          your experience.
                                        </span>
                                      </span>
                                      <bdt className="statement-end-if-in-editor">
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </li>
                                  </ul>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: "15px" }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                  </div>
                                  <ul>
                                    <li style={{ lineHeight: "1.5" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: "15px" }}>
                                          <strong>
                                            To identify usage trends.
                                          </strong>{" "}
                                          We may process information about how
                                          you use our Services to better
                                          understand how they are being used so
                                          we can improve them.
                                        </span>
                                      </span>
                                      <bdt className="statement-end-if-in-editor">
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </li>
                                  </ul>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: "15px" }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                    </div>
                                    <ul>
                                      <li style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text">
                                            <strong>
                                              To determine the effectiveness of
                                              our marketing and promotional
                                              campaigns.
                                            </strong>{" "}
                                            We may process your information to
                                            better understand how to provide
                                            marketing and promotional campaigns
                                            that are most relevant to you.
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                        </span>
                                      </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: "15px" }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: "15px" }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: "15px" }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <bdt className="block-component">
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <br />
                                          </div>
                                          <div
                                            id="whoshare"
                                            style={{ lineHeight: "1.5" }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(127, 127, 127)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "15px",
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "15px",
                                                      color: "rgb(89, 89, 89)",
                                                    }}
                                                  >
                                                    <span
                                                      id="control"
                                                      style={{
                                                        color: "rgb(0, 0, 0)",
                                                      }}
                                                    >
                                                      <strong>
                                                        <span data-custom-class="heading_1">
                                                          3. WHEN AND WITH WHOM
                                                          DO WE SHARE YOUR
                                                          PERSONAL INFORMATION?
                                                        </span>
                                                      </strong>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <br />
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    <em>In Short:</em>
                                                  </strong>
                                                  <em>
                                                    {" "}
                                                    We may share information in
                                                    specific situations
                                                    described in this section
                                                    and/or with the following{" "}
                                                    <bdt className="block-component"></bdt>
                                                    third parties.
                                                  </em>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span
                                              style={{
                                                fontSize: "15px",
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: "15px",
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <bdt className="block-component"></bdt>
                                                </span>
                                              </span>
                                            </span>
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <br />
                                          </div>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span data-custom-class="body_text">
                                                We{" "}
                                                <bdt className="block-component" />
                                                may need to share your personal
                                                information in the following
                                                situations:
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    Business Transfers.
                                                  </strong>{" "}
                                                  We may share or transfer your
                                                  information in connection
                                                  with, or during negotiations
                                                  of, any merger, sale of
                                                  company assets, financing, or
                                                  acquisition of all or a
                                                  portion of our business to
                                                  another company.
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                              </span>
                                            </span>
                                          </div>
                                          <ul>
                                            <li style={{ lineHeight: "1.5" }}>
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <strong>
                                                    When we use Google Maps
                                                    Platform APIs.
                                                  </strong>{" "}
                                                  We may share your information
                                                  with certain Google Maps
                                                  Platform APIs (e.g.
                                                  <bdt className="block-component" />
                                                  ,
                                                  <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                  Google Maps API, Places API).
                                                  To find out more about
                                                  Google’s Privacy Policy,
                                                  please refer to this
                                                </span>
                                              </span>
                                              <a
                                                data-custom-class="link"
                                                href="https://policies.google.com/privacy"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                              >
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    link
                                                  </span>
                                                </span>
                                              </a>
                                              <span
                                                style={{ fontSize: "15px" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  .
                                                  <bdt className="block-component" />
                                                  <bdt className="block-component" />
                                                  We obtain and store on your
                                                  device (
                                                  <bdt className="block-component" />
                                                  "cache"
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                  ) your location
                                                  <bdt className="block-component" />
                                                  . You may revoke your consent
                                                  anytime by contacting us at
                                                  the contact details provided
                                                  at the end of this document.
                                                  <bdt className="statement-end-if-in-editor"></bdt>
                                                  <bdt className="block-component" />
                                                </span>
                                              </span>
                                            </li>
                                          </ul>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: "15px" }}>
                                              <bdt className="block-component">
                                                <span data-custom-class="body_text" />
                                              </bdt>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span
                                                  style={{ fontSize: "15px" }}
                                                >
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: "15px" }}
                                                  >
                                                    <span data-custom-class="body_text"></span>
                                                  </span>
                                                </bdt>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <bdt className="block-component">
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </bdt>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt className="block-component">
                                                                <span data-custom-class="heading_1" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="cookies"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  4. DO WE USE
                                                                  COOKIES AND
                                                                  OTHER TRACKING
                                                                  TECHNOLOGIES?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            We may use cookies
                                                            and other tracking
                                                            technologies to
                                                            collect and store
                                                            your information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may use cookies and
                                                          similar tracking
                                                          technologies (like web
                                                          beacons and pixels) to
                                                          access or store
                                                          information. Specific
                                                          information about how
                                                          we use such
                                                          technologies and how
                                                          you can refuse certain
                                                          cookies is set out in
                                                          our Cookie Notice
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                              .
                                                            </span>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component"></bdt>
                                                                        </span>
                                                                        <bdt className="block-component">
                                                                          <span data-custom-class="body_text">
                                                                            <bdt className="block-component"></bdt>
                                                                          </span>
                                                                        </bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="inforetain"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  5. HOW LONG DO
                                                                  WE KEEP YOUR
                                                                  INFORMATION?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            We keep your
                                                            information for as
                                                            long as necessary to
                                                            <bdt className="block-component"></bdt>
                                                            fulfill
                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                            the purposes
                                                            outlined in this
                                                            privacy notice
                                                            unless otherwise
                                                            required by law.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We will only keep your
                                                          personal information
                                                          for as long as it is
                                                          necessary for the
                                                          purposes set out in
                                                          this privacy notice,
                                                          unless a longer
                                                          retention period is
                                                          required or permitted
                                                          by law (such as tax,
                                                          accounting, or other
                                                          legal requirements).
                                                          <bdt className="block-component"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          When we have no
                                                          ongoing legitimate
                                                          business need to
                                                          process your personal
                                                          information, we will
                                                          either delete or
                                                          <bdt className="block-component"></bdt>
                                                          anonymize
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          such information, or,
                                                          if this is not
                                                          possible (for example,
                                                          because your personal
                                                          information has been
                                                          stored in backup
                                                          archives), then we
                                                          will securely store
                                                          your personal
                                                          information and
                                                          isolate it from any
                                                          further processing
                                                          until deletion is
                                                          possible.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="infosafe"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  6. HOW DO WE
                                                                  KEEP YOUR
                                                                  INFORMATION
                                                                  SAFE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            We aim to protect
                                                            your personal
                                                            information through
                                                            a system of{" "}
                                                            <bdt className="block-component"></bdt>
                                                            organizational
                                                            <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                            and technical
                                                            security measures.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We have implemented
                                                          appropriate and
                                                          reasonable technical
                                                          and{" "}
                                                          <bdt className="block-component"></bdt>
                                                          organizational
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          security measures
                                                          designed to protect
                                                          the security of any
                                                          personal information
                                                          we process. However,
                                                          despite our safeguards
                                                          and efforts to secure
                                                          your information, no
                                                          electronic
                                                          transmission over the
                                                          Internet or
                                                          information storage
                                                          technology can be
                                                          guaranteed to be 100%
                                                          secure, so we cannot
                                                          promise or guarantee
                                                          that hackers,
                                                          cybercriminals, or
                                                          other{" "}
                                                          <bdt className="block-component"></bdt>
                                                          unauthorized
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          third parties will not
                                                          be able to defeat our
                                                          security and
                                                          improperly collect,
                                                          access, steal, or
                                                          modify your
                                                          information. Although
                                                          we will do our best to
                                                          protect your personal
                                                          information,
                                                          transmission of
                                                          personal information
                                                          to and from our
                                                          Services is at your
                                                          own risk. You should
                                                          only access the
                                                          Services within a
                                                          secure environment.
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                          </span>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="privacyrights"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  7. WHAT ARE
                                                                  YOUR PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <em>
                                                                    <bdt className="block-component"></bdt>
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            You may review,
                                                            change, or terminate
                                                            your account at any
                                                            time.
                                                          </em>
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    ></span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are located in
                                                          the EEA or UK and you
                                                          believe we are
                                                          unlawfully processing
                                                          your personal
                                                          information, you also
                                                          have the right to
                                                          complain to your local
                                                          data protection
                                                          supervisory authority.
                                                          You can find their
                                                          contact details here:{" "}
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(48, 48, 241)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <a
                                                                      data-custom-class="link"
                                                                      href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                      rel="noopener noreferrer"
                                                                      target="_blank"
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize:
                                                                            "15px",
                                                                        }}
                                                                      >
                                                                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                      </span>
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are located in
                                                          Switzerland, the
                                                          contact details for
                                                          the data protection
                                                          authorities are
                                                          available here:
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(48, 48, 241)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="withdrawconsent"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              Withdrawing your
                                                              consent:
                                                            </u>
                                                          </strong>
                                                          If we are relying on
                                                          your consent to
                                                          process your personal
                                                          information,
                                                          <bdt className="block-component"></bdt>{" "}
                                                          which may be express
                                                          and/or implied consent
                                                          depending on the
                                                          applicable law,
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          you have the right to
                                                          withdraw your consent
                                                          at any time. You can
                                                          withdraw your consent
                                                          at any time by
                                                          contacting us by using
                                                          the contact details
                                                          provided in the
                                                          section{" "}
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            HOW CAN YOU CONTACT
                                                            US ABOUT THIS
                                                            NOTICE?
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                          "
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          below
                                                          <bdt className="block-component"></bdt>
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        However, please note
                                                        that this will not
                                                        affect the lawfulness of
                                                        the processing before
                                                        its withdrawal nor,
                                                        <bdt className="block-component"></bdt>{" "}
                                                        when applicable law
                                                        allows,
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        will it affect the
                                                        processing of your
                                                        personal information
                                                        conducted in reliance on
                                                        lawful processing
                                                        grounds other than
                                                        consent.
                                                        <bdt className="block-component"></bdt>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <strong>
                                                          <u>
                                                            Opting out of
                                                            marketing and
                                                            promotional
                                                            communications:
                                                          </u>
                                                        </strong>
                                                        <strong>
                                                          <u></u>
                                                        </strong>
                                                        You can unsubscribe from
                                                        our marketing and
                                                        promotional
                                                        communications at any
                                                        time by
                                                        <bdt className="block-component"></bdt>{" "}
                                                        clicking on the
                                                        unsubscribe link in the
                                                        emails that we send,
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                        <bdt className="block-component"></bdt>{" "}
                                                        replying{" "}
                                                        <bdt className="block-component"></bdt>
                                                        "STOP" or "UNSUBSCRIBE"
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        to the SMS messages that
                                                        we send,
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                        <bdt className="block-component"></bdt>{" "}
                                                        or by contacting us
                                                        using the details
                                                        provided in the section
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                    <a
                                                      data-custom-class="link"
                                                      href="#contact"
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          HOW CAN YOU CONTACT US
                                                          ABOUT THIS NOTICE?
                                                        </span>
                                                      </span>
                                                    </a>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="block-component"></bdt>
                                                        "
                                                        <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                        below. You will then be
                                                        removed from the
                                                        marketing lists.
                                                        However, we may still
                                                        communicate with you —
                                                        for example, to send you
                                                        service-related messages
                                                        that are necessary for
                                                        the administration and
                                                        use of your account, to
                                                        respond to service
                                                        requests, or for other
                                                        non-marketing purposes.
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <bdt className="statement-end-if-in-editor"></bdt>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <bdt className="block-component"></bdt>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <u>
                                                              Cookies and
                                                              similar
                                                              technologies:
                                                            </u>
                                                          </strong>
                                                          Most Web browsers are
                                                          set to accept cookies
                                                          by default. If you
                                                          prefer, you can
                                                          usually choose to set
                                                          your browser to remove
                                                          cookies and to reject
                                                          cookies. If you choose
                                                          to remove cookies or
                                                          reject cookies, this
                                                          could affect certain
                                                          features or services
                                                          of our Services. To
                                                          opt out of
                                                          interest-based
                                                          advertising by
                                                          advertisers on our
                                                          Services visit{" "}
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(48, 48, 241)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <a
                                                                data-custom-class="link"
                                                                href="http://www.aboutads.info/choices/"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  http://www.aboutads.info/choices/
                                                                </span>
                                                              </a>
                                                            </span>
                                                          </span>
                                                          .
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="block-component"></bdt>
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        If you have questions or
                                                        comments about your
                                                        privacy rights, you may
                                                        email us at{" "}
                                                        <bdt className="question">
                                                          Info@shreyashautomotives.com
                                                        </bdt>
                                                        .
                                                      </span>
                                                    </span>
                                                    <bdt className="statement-end-if-in-editor">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="DNT"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  8. CONTROLS
                                                                  FOR
                                                                  DO-NOT-TRACK
                                                                  FEATURES
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          Most web browsers and
                                                          some mobile operating
                                                          systems and mobile
                                                          applications include a
                                                          Do-Not-Track (
                                                          <bdt className="block-component"></bdt>
                                                          "DNT"
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                          ) feature or setting
                                                          you can activate to
                                                          signal your privacy
                                                          preference not to have
                                                          data about your online
                                                          browsing activities
                                                          monitored and
                                                          collected. At this
                                                          stage no uniform
                                                          technology standard
                                                          for{" "}
                                                          <bdt className="block-component"></bdt>
                                                          recognizing
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          and implementing DNT
                                                          signals has been
                                                          <bdt className="block-component"></bdt>
                                                          finalized
                                                          <bdt className="statement-end-if-in-editor"></bdt>
                                                          . As such, we do not
                                                          currently respond to
                                                          DNT browser signals or
                                                          any other mechanism
                                                          that automatically
                                                          communicates your
                                                          choice not to be
                                                          tracked online. If a
                                                          standard for online
                                                          tracking is adopted
                                                          that we must follow in
                                                          the future, we will
                                                          inform you about that
                                                          practice in a revised
                                                          version of this
                                                          privacy notice.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="caresidents"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  9. DO
                                                                  CALIFORNIA
                                                                  RESIDENTS HAVE
                                                                  SPECIFIC
                                                                  PRIVACY
                                                                  RIGHTS?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            <em>In Short:</em>
                                                          </strong>
                                                          <em>
                                                            Yes, if you are a
                                                            resident of
                                                            California, you are
                                                            granted specific
                                                            rights regarding
                                                            access to your
                                                            personal
                                                            information.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          California Civil Code
                                                          Section 1798.83, also
                                                          known as the{" "}
                                                          <bdt className="block-component"></bdt>
                                                          "Shine The Light"
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          law, permits our users
                                                          who are California
                                                          residents to request
                                                          and obtain from us,
                                                          once a year and free
                                                          of charge, information
                                                          about categories of
                                                          personal information
                                                          (if any) we disclosed
                                                          to third parties for
                                                          direct marketing
                                                          purposes and the names
                                                          and addresses of all
                                                          third parties with
                                                          which we shared
                                                          personal information
                                                          in the immediately
                                                          preceding calendar
                                                          year. If you are a
                                                          California resident
                                                          and would like to make
                                                          such a request, please
                                                          submit your request in
                                                          writing to us using
                                                          the contact
                                                          information provided
                                                          below.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you are under 18
                                                          years of age, reside
                                                          in California, and
                                                          have a registered
                                                          account with Services,
                                                          you have the right to
                                                          request removal of
                                                          unwanted data that you
                                                          publicly post on the
                                                          Services. To request
                                                          removal of such data,
                                                          please contact us
                                                          using the contact
                                                          information provided
                                                          below and include the
                                                          email address
                                                          associated with your
                                                          account and a
                                                          statement that you
                                                          reside in California.
                                                          We will make sure the
                                                          data is not publicly
                                                          displayed on the
                                                          Services, but please
                                                          be aware that the data
                                                          may not be completely
                                                          or comprehensively
                                                          removed from all our
                                                          systems (e.g.
                                                          <bdt className="block-component"></bdt>
                                                          ,
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          backups, etc.).
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                        }}
                                                      ></span>
                                                    </bdt>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="policyupdates"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  10. DO WE MAKE
                                                                  UPDATES TO
                                                                  THIS NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <em>
                                                            <strong>
                                                              In Short:
                                                            </strong>
                                                            Yes, we will update
                                                            this notice as
                                                            necessary to stay
                                                            compliant with
                                                            relevant laws.
                                                          </em>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We may update this
                                                          privacy notice from
                                                          time to time. The
                                                          updated version will
                                                          be indicated by an
                                                          updated{" "}
                                                          <bdt className="block-component"></bdt>
                                                          "Revised"
                                                          <bdt className="statement-end-if-in-editor"></bdt>{" "}
                                                          date and the updated
                                                          version will be
                                                          effective as soon as
                                                          it is accessible. If
                                                          we make material
                                                          changes to this
                                                          privacy notice, we may
                                                          notify you either by
                                                          prominently posting a
                                                          notice of such changes
                                                          or by directly sending
                                                          you a notification. We
                                                          encourage you to
                                                          review this privacy
                                                          notice frequently to
                                                          be informed of how we
                                                          are protecting your
                                                          information.
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="contact"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  11. HOW CAN
                                                                  YOU CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          If you have questions
                                                          or comments about this
                                                          notice, you may
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                              email us at{" "}
                                                              <bdt className="question">
                                                                Info@shreyashautomotives.com
                                                              </bdt>
                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                            </span>
                                                          </span>
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                or by post to:
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="question">
                                                                    Shreyash
                                                                    Automotives
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span data-custom-class="body_text">
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="question">
                                                          23, Mayapuri
                                                          Industrial Area Phase
                                                          II, Mayapuri, New
                                                          Delhi, Delhi 110064,
                                                          India
                                                        </bdt>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <bdt className="question">
                                                          New Delhi
                                                        </bdt>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                            }}
                                                          >
                                                            <bdt className="block-component"></bdt>
                                                            ,{" "}
                                                            <bdt className="question">
                                                              Delhi
                                                            </bdt>
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                            <bdt className="block-component"></bdt>
                                                            <bdt className="question">
                                                              110064
                                                            </bdt>
                                                            <bdt className="statement-end-if-in-editor"></bdt>
                                                            <bdt className="block-component"></bdt>
                                                            <bdt className="block-component"></bdt>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt className="block-component"></bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <bdt className="question">
                                                          India
                                                        </bdt>
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "15px",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor">
                                                                        <span
                                                                          style={{
                                                                            fontSize:
                                                                              "15px",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <bdt className="statement-end-if-in-editor"></bdt>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </bdt>
                                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "15px",
                                                                      }}
                                                                    >
                                                                      <bdt className="statement-end-if-in-editor"></bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <bdt className="statement-end-if-in-editor">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "15px",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component">
                                                                        <bdt className="block-component"></bdt>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize:
                                                                    "15px",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <bdt className="block-component"></bdt>
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    id="request"
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(127, 127, 127)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: "15px",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: "15px",
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: "15px",
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              id="control"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <strong>
                                                                <span data-custom-class="heading_1">
                                                                  12. HOW CAN
                                                                  YOU REVIEW,
                                                                  UPDATE, OR
                                                                  DELETE THE
                                                                  DATA WE
                                                                  COLLECT FROM
                                                                  YOU?
                                                                </span>
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <br />
                                                  </div>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "15px",
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component"></bdt>
                                                          Based on the
                                                          applicable laws of
                                                          your country, you may
                                                          have the right to
                                                          request access to the
                                                          personal information
                                                          we collect from you,
                                                          change that
                                                          information, or delete
                                                          it.{" "}
                                                          <bdt className="else-block"></bdt>
                                                          To request to review,
                                                          update, or delete your
                                                          personal information,
                                                          please
                                                          <bdt className="block-component"></bdt>
                                                          submit a request form
                                                          by clicking
                                                        </span>
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(48, 48, 241)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "15px",
                                                              }}
                                                            >
                                                              <a
                                                                data-custom-class="link"
                                                                href="https://app.termly.io/notify/f3e40d43-c747-4353-bfa5-3a8c11b43c12"
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                              >
                                                                here
                                                              </a>
                                                            </span>
                                                          </span>
                                                        </span>
                                                        <bdt className="block-component">
                                                          <span data-custom-class="body_text"></span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      .
                                                    </span>
                                                  </div>
                                                  <style
                                                    dangerouslySetInnerHTML={{
                                                      __html:
                                                        "\n                                                                                        ul {\n\n                                                                                            list-style-type: square;\n\n                                                                                        }\n\n                                                                                        ul>li>ul {\n\n                                                                                            list-style-type: circle;\n\n                                                                                        }\n\n                                                                                        ul>li>ul>li>ul {\n\n                                                                                            list-style-type: square;\n\n                                                                                        }\n\n                                                                                        ol li {\n\n                                                                                            font-family: Arial;\n\n                                                                                        }\n                                                                                    ",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    color: "#595959",
                                                    fontSize: "14px",
                                                    fontFamily: "Arial",
                                                    paddingTop: "16px",
                                                  }}
                                                >
                                                  This privacy policy was
                                                  created using Termly's{" "}
                                                  <a
                                                    style={{
                                                      color:
                                                        "rgb(48, 48, 241) !important",
                                                    }}
                                                    href="https://termly.io/products/privacy-policy-generator/"
                                                  >
                                                    Privacy Policy Generator
                                                  </a>
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Stack>
    </>
  );
}
