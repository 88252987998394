import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Journey from "../Components/Journey";
import GoToTop from "../Components/GoToTop";
import Location from "../Components/Location";

export default function About() {
  return (
    <Stack maxWidth={"xl"} mx={"auto"}>
      <Stack mx={8}>
        <Stack mb={"6rem"}>
          <Stack maxWidth={"xl"} mx={"auto"} spacing={2} my="4rem">
            <Typography
              sx={{ typography: { xs: "textSmSemi", md: "textMdSemi" } }}
              color="primary.gray600"
            >
              About Us
            </Typography>
            <Typography
              sx={{ typography: { xs: "displayMdSemi", md: "displayLgSemi" } }}
              color="primary.gray900"
            >
              Shreyash Automotives
            </Typography>
            <Typography
              pt={2}
              sx={{
                typography: { xs: "textMdReg", md: "textLgReg" },
              }}
            >
              Shreyash Automotives, led by Mr. Ashish Bansal, is your go-to
              destination for authorized outlets for Maruti Suzuki. Our outlets
              in Delhi include Nexa, Arena, True Value, and Maruti Suzuki
              Service Centers.
              <br></br>
              <br></br>
              Whether you're in the market for a sedan, hatchback, SUV, or
              something else, we've got something to suit every driver's needs
              and preferences. Our wide range of services includes sales of new
              and used cars, financing options, and professional maintenance and
              repair services. Our team is dedicated to providing you with the
              best possible experience when it comes to finding your dream car
              and keeping it in ultimate shape.
              <br></br>
              <br></br>
              At our store, you'll also find a team of experienced and friendly
              sales associates who are dedicated to assisting you in finding the
              perfect vehicle to meet your needs.
              <br></br>
              <br></br>
              In addition to our extensive inventory of vehicles, we also have a
              state-of-the-art service department staffed by factory-trained
              technicians. No matter your maintenance or repair needs, we are
              equipped to handle them quickly and precisely. We also offer a
              variety of service specials and promotions to help you save on
              your car care needs.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Journey />
      <Location />
      <GoToTop />
    </Stack>
  );
}
