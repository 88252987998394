import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import "yup-phone";
import * as yup from "yup";
import useApi from "../hooks/use-api";
import { useRef } from "react";
import Register from "./Forms/Register";
import Response from "../Utils/Response";

export default function Form(props) {
  const [cars, setCars] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });
  const captchaRef = useRef(null);

  // ? ************************************* Validations ********************************** */
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    mobile: yup
      .string()
      .phone(null, true, "Invalid Number")
      .required("Your mobile number is required"),
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your full name."),
    car_id: yup.string().required("Please select the car model."),
    buyer_type: yup.string().required("Please select the buyer type."),
    disclaimer: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  // ? ******************************************* Cars Data ******************************** */
  const allCars = async () => {
    try {
      const result = await useApi.getRequest("cars", false);
      if (result.status) {
        return setCars(result.data);
      }

      throw new Error(result.message);
    } catch (error) {
      // return Toast.fire({})
      console.log(error.message);
    }
  };

  // ? *************************************** show the data ******************************** */
  useEffect(() => {
    allCars();
  }, []);

  // ? *************************************** Register the user ******************************** */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      car_id: "",
      disclaimer: false,
      buyer_type: "",
      checkField: "",
      token: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      // setFormVisible(false);
      try {
        setIsRegistering(true);
        const result = await useApi.postRequest("users", false, values);
        setIsRegistering(false);
        if (result.status) {
          formik.resetForm();
          action.resetForm();
          captchaRef.current.reset();
          return setIsResponse({
            status: true,
            type: "success",
            message: result.message,
            subText:
              "Thank you for showing interest in our products/services. We will be in touch soon.",
            buttonText: "Back",
          });
        }

        throw new Error(result.message);
      } catch (error) {
        setIsResponse({
          status: true,
          type: "failed",
          message: error.message,
          subText: "Please Try Again.",
          buttonText: "Try Again",
        });
      }
    },
  });

  return (
    <div>
      {!isResponse.status && (
        <Register
          formRef={props.formRef}
          formik={formik}
          cars={cars}
          isRegistering={isRegistering}
          captchaRef={captchaRef}
        />
      )}
      {isResponse.status && (
        <Response response={isResponse} setIsResponse={setIsResponse} />
      )}
    </div>
  );
}
