import React from "react";
import Carousel from "react-material-ui-carousel";
import { Stack } from "@mui/system";
import banners from "../store/banner";

const MainCarousel = (props) => {
  return (
    <Carousel navButtonsAlwaysVisible>
      {banners.map((banner) =>
        banner.type === "image" ? (
          <Stack key={banner.id}>
            <img
              src={banner.name}
              alt={banner.name}
              height="100%"
              width="100%"
            />
          </Stack>
        ) : (
          <Stack key={banner.id}>
            <video autoPlay muted loop>
              <source src={banner.name} />
            </video>
          </Stack>
        )
      )}
    </Carousel>
  );
};

export default MainCarousel;
