import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import "yup-phone";
import * as yup from "yup";
import useApi from "../hooks/use-api";
import axios from "axios";
import Sell from "./Forms/TrueValue/Sell";
import Response from "../Utils/Response";

export default function SellForm(props) {
  const [isRegistering, setIsRegistering] = useState(false);
  const captchaRef = useRef(null);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  // ? ************************************* Validations ********************************** */
  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    mobile: yup
      .string()
      .phone(null, true, "Invalid Number")
      .required("Your mobile number is required"),
    chassis_number: yup
      .string()
      .required("Your VIN/Chassis number is required")
      .max(17, "Not more than 17 characters")
      .matches(
        "^[A-HJ-NPR-Z\\d]{8}[\\dX][A-HJ-NPR-Z\\d]{2}\\d{6}$",
        "Please enter a valid VIN/Chassis number"
      ),
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your full name."),
    disclaimer: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  // ? *************************************** upload file ******************************** */
  const onFileChangeHandler = async (event) => {
    try {
      const formData = new FormData();
      formData.append("image", event.target.files[0]);
      props.setIsUploading(true);
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          props.setPercentage(Math.floor((loaded * 100) / total));
        },
        headers: {
          "x-api-key": process.env.REACT_APP_FILES_API_KEY,
        },
      };

      const fileUploadToServer = await axios.post(
        `${process.env.REACT_APP_API_URL}files?folderPath=assets/images/sell-cars&name=image&isMultiple=false&length=3`,
        formData,
        options
      );
      if (fileUploadToServer.data.status) {
        props.setIsUploading(false);
        props.setIsUploaded(true);
        props.setFileName(fileUploadToServer.data.data.name);
        return formik.setFieldValue("image", fileUploadToServer.data.data.file);
      }

      throw new Error(fileUploadToServer.data.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? *************************************** delete upload file ******************************** */
  const deleteFileHandler = async () => {
    try {
      const result = await useApi.deleteRequest(
        `files/${formik.values.image}?folderPath=assets/images/sell-cars`,
        false
      );
      if (result.status) {
        props.setIsUploaded(false);
        props.setIsUploading(false);
        return props.setFileName("");
      }

      throw new Error(result.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  // ? *************************************** Register the user ******************************** */
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      chassis_number: "",
      mobile: "",
      image: "",
      disclaimer: false,
      checkField: "",
      token: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      try {
        setIsRegistering(true);
        const result = await useApi.postRequest("sell-cars", false, values);
        setIsRegistering(false);

        if (result.status) {
          action.resetForm();
          formik.resetForm();
          return setIsResponse({
            status: true,
            type: "success",
            message: result.message,
            subText:
              "Thank you for sharing your details. We will be in touch soon.",
            buttonText: "Back",
          });
        }

        throw new Error(result.message);
      } catch (error) {
        setIsResponse({
          status: true,
          type: "failed",
          message: error.message,
          subText: "Please Try Again.",
          buttonText: "Try Again",
        });
      }
    },
  });

  return (
    <>
      {!isResponse.status && (
        <Sell
          formRef={props.formRef}
          isUploading={props.isUploading}
          isUploaded={props.isUploaded}
          fileName={props.fileName}
          formik={formik}
          onFileChangeHandler={onFileChangeHandler}
          deleteFileHandler={deleteFileHandler}
          captchaRef={captchaRef}
          isRegistering={isRegistering}
        />
      )}
      {isResponse.status && (
        <Response response={isResponse} setIsResponse={setIsResponse} />
      )}
    </>
  );
}
