import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import Recaptcha from "../Recaptcha";

const Service = (props) => {
  return (
    <Stack my={"3%"} mx={"3%"} bgcolor={"primary.white"}>
      <Typography
        textAlign={"center"}
        sx={{ typography: { xs: "displayXsSemi", md: "displaySmSemi" } }}
      >
        BOOK YOUR CAR SERVICE
      </Typography>
      <form onSubmit={props.formik.handleSubmit}>
        <Stack
          spacing={"15px"}
          direction={"column"}
          justifyContent="center"
          display={"flex"}
          alignItems="center"
        >
          <TextField
            fullWidth
            sx={{
              maxWidth: "sm",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            color="secondary"
            size="medium"
            name="name"
            variant="standard"
            label="Name *"
            value={props.formik.values.name}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            error={
              props.formik.touched.name && Boolean(props.formik.errors.name)
            }
            helperText={props.formik.touched.name && props.formik.errors.name}
          />

          <TextField
            fullWidth
            sx={{
              maxWidth: "sm",
              ".MuiFormHelperText-root": {
                typography: "textSmReg",
                color: "#d3302f",
              },
            }}
            color="secondary"
            size="medium"
            name="mobile"
            inputProps={{ maxLength: 10 }}
            variant="standard"
            label="Phone/mobile *"
            value={props.formik.values.mobile}
            onChange={props.formik.handleChange}
            onBlur={props.formik.handleBlur}
            error={
              props.formik.touched.mobile && Boolean(props.formik.errors.mobile)
            }
            helperText={
              props.formik.touched.mobile && props.formik.errors.mobile
            }
          />
          <Stack
            display={"flex"}
            alignItems="flex-start"
            justifyContent={"start"}
          >
            <FormControlLabel
              sx={{
                pt: 2,
                maxWidth: "sm",
                ".MuiFormControlLabel-label": {
                  typography: "textXsReg",
                  color: "primary.gray600",
                },
              }}
              control={
                <Checkbox
                  sx={{
                    ".MuiCheckbox-root": { verticalAlign: "top" },
                  }}
                  size="small"
                  color="secondary"
                  checked={props.formik.disclaimer}
                />
              }
              onChange={props.formik.handleChange}
              onBlur={props.formik.handleBlur}
              error={
                props.formik.touched.disclaimer &&
                Boolean(props.formik.errors.disclaimer)
              }
              helperText={
                props.formik.touched.disclaimer &&
                props.formik.errors.disclaimer
              }
              label="Disclaimer: I agree that by clicking the ‘Book Now’ button below, I am explicitly soliciting a call from Maruti Suzuki India Ltd. Or its partners on my ‘Mobile’."
              name="disclaimer"
            />

            {props.formik.errors.disclaimer ? (
              <Stack
                display={"flex"}
                alignItems="flex-start"
                justifyContent={"start"}
                pt={1}
              >
                <Typography sx={{ typography: "textXsReg" }} color="#D3302F">
                  {props.formik.errors.disclaimer}
                </Typography>
              </Stack>
            ) : null}
          </Stack>
          <Recaptcha
            name="token"
            captchaRef={props.captchaRef}
            handleChange={props.formik.handleChange}
            setFieldValue={props.formik.setFieldValue}
          />
          <TextField
            name="checkField"
            sx={{ display: "none" }}
            onChange={props.formik.handleChange}
          />

          <Button
            size="small"
            variant="contain"
            type="submit"
            sx={[
              {
                color: "primary.white",
                backgroundColor: "primary.black",
                my: "10px",
                ml: "10px",
                "&.MuiButton-root": {
                  border: "1px solid",
                  borderColor: "black",
                  borderRadius: "0px",
                },
              },
              {
                "&:hover": {
                  color: "primary.black",
                  backgroundColor: "primary.white",
                },
              },
            ]}
          >
            Book Service
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default Service;
