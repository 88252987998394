import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import animationData from "../assets/images/404-error";
const Error = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const navigate = useNavigate();
  return (
    <Stack
      minH={"70vh"}
      display="flex"
      justifyContent={"center"}
      alignItems="center"
    >
      <Stack
        pt={"5%"}
        pb={5}
        maxWidth={"md"}
        display="flex"
        alignItems={"center"}
        justifyContent="center"
      >
        <Box maxWidth={{ xs: "70%", md: "50%" }}>
          <Lottie options={defaultOptions} />
        </Box>
        {/* <Link p="3" href={process.env.REACT_APP_BASE_URL}> */}

        {/* </Link> */}
        <Typography
          my={3}
          mx={3}
          textAlign={"center"}
          sx={{ typography: "textLgReg", color: "primary.gray600" }}
        >
          We apologize for the inconvenience, the page you are trying to reach
          cannot be found. Please check the URL or head back to our home page
        </Typography>
        <Button
          variant="contain"
          onClick={() => {
            navigate("/");
          }}
          sx={[
            {
              color: "primary.white",
              backgroundColor: "primary.black",
              my: "10px",
              ml: "10px",
              "&.MuiButton-root": {
                border: "1px solid",
                borderColor: "black",
                borderRadius: "0px",
              },
            },
            {
              "&:hover": {
                color: "primary.black",
                backgroundColor: "primary.white",
              },
            },
          ]}
        >
          Back to home
        </Button>
      </Stack>
    </Stack>
  );
};

export default Error;
