import { Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import CarCard from "../CarCard";

const Card = (props) => {
  return (
    <>
      <Typography
        sx={{
          typography: { xs: "displayMdSemi", md: "displayLgBold" },
          my: "2%",
          mt: "10%",
        }}
      >
        {props.heading}
      </Typography>

      <Grid
        container
        rowSpacing={8}
        columnSpacing={8}
        direction="row"
        // columns={{ xs: 4, md: 6, lg: 12 }}
        alignItems={{ xs: "center", md: "normal" }}
        justifyContent={{ xs: "center", md: "normal" }}
      >
        {props.cars.map((car) => (
          <Grid item xs={12} md={6} lg={4} key={car.id}>
            <Stack
              display={"flex"}
              alignItems={{ xs: "center", md: "normal" }}
              justifyContent={{ xs: "center", md: "normal" }}
            >
              <CarCard
                key={car.id}
                car={car}
                type={props.type}
                bookNowRef={props.bookNowRef}
              />
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Card;
