import { Box, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import truevalue from "../../assets/images/truevalue.webp";
import Typography from "@mui/material/Typography";
// import Form from "../Form";
// import CarCard from "../CarCard";
import BuyForm from "../BuyForm";
import SellForm from "../SellForm";

import useApi from "../../hooks/use-api";
const BannerImage = lazy(() => import("../BannerImage"));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TrueValue() {
  const [value, setValue] = React.useState(0);
  const [cars, setCars] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [percentage, setPercentage] = useState(0);

  // ? ******************************************* Cars Data ******************************** */
  const allCars = async () => {
    try {
      const result = await useApi.getRequest("cars", false);
      if (result.status) {
        return setCars(result.data);
      }

      throw new Error(result.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    allCars();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Stack>
      {/* {temp ? (
        <Skeleton sx={{ mx: "20px" }} variant="rectangular" height={430} />
      ) : (
        <Box component={"img"} src={truevalue} maxWidth={"100%"} />
      )} */}
      <Suspense
        fallback={
          <Skeleton sx={{ mx: "20px" }} variant="rectangular" height={430} />
        }
      >
        <BannerImage value={truevalue} type={"img"} />
      </Suspense>

      <Stack
        display={"flex"}
        my="2%"
        justifyContent="center"
        alignItems={"center"}
      >
        <Box sx={{ maxWidth: "md", px: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              sx={{
                ".Mui-selected": { background: "black", color: "white" },
              }}
              value={value}
              indicatorColor="secondary"
              onChange={handleChange}
              variant="fullWidth"
              aria-label="basic tabs example"
            >
              <Tab label="Buy" {...a11yProps(0)} />
              <Tab label="Sell" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BuyForm
              cars={cars}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <SellForm
              setIsUploading={setIsUploading}
              isUploading={isUploading}
              setIsUploaded={setIsUploaded}
              setFileName={setFileName}
              fileName={fileName}
              isUploaded={isUploaded}
              setPercentage={setPercentage}
              percentage={percentage}
            />
          </TabPanel>
        </Box>
      </Stack>
    </Stack>
  );
}
