import { Divider, Grid, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import logo from "../assets/images/footer-logo.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import cardData from "../store/AboutCardData";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
import GoToTop from "./GoToTop";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Stack sx={{ backgroundColor: "primary.gray800" }} px="3%">
        <Stack
          display="flex"
          justifyContent={{ xs: "center", md: "space-between" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          direction={{ xs: "column", md: "row" }}
          py="2%"
        >
          <Stack direction={"row"} display="flex" alignItems={"center"}>
            <Stack
              mx={2}
              pr={{ xs: "0%", md: "8%" }}
              py={{ xs: "5%", md: "0%" }}
            >
              <img src={logo} alt="logo" width={"200px"} />
            </Stack>
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{
                "&.MuiDivider-root": {
                  borderColor: "white",
                },
                display: { xs: "none", md: "initial" },
                minHeight: "140px",
              }}
              flexItem
            />
          </Stack>

          <Grid my={3} mx={{ md: 1 }} container spacing={3}>
            {cardData.map((data, index) => {
              return (
                <Grid item xs={12} sm={6} md={3}>
                  <Stack
                    direction={{ md: "row" }}
                    justifyContent="center"
                    alignItems={"center"}
                    display={{ md: "flex" }}
                  >
                    <Stack>
                      {" "}
                      <Typography
                        color={"white"}
                        sx={{ typography: "textLgSemi" }}
                        px={"15px"}
                      >
                        {data.Mainheading}
                        <br></br>
                        <Stack mt={2} direction={"row"}>
                          <Link target={"_blank"} href={data.googleLink}>
                            <LocationOnIcon
                              sx={{
                                color: "primary.gray300",
                                cursor: "pointer",
                                width: "20px",
                              }}
                            />
                          </Link>
                          <Link target={"_blank"} href={data.facebookLink}>
                            <FacebookIcon
                              sx={{
                                color: "primary.gray300",
                                mx: "5px",
                                width: "20px",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                        </Stack>
                      </Typography>
                      <Typography
                        maxWidth={{ xs: "90%", md: "100%" }}
                        color={"white"}
                        sx={{ typography: "textXsReg" }}
                        py="5px"
                        px={"15px"}
                      >
                        {data.Subheading}
                      </Typography>
                    </Stack>
                    {index <= 2 && (
                      <Divider
                        orientation="vertical"
                        sx={{
                          "&.MuiDivider-root": {
                            borderColor: "white",
                          },
                          minHeight: { md: "125.73px" },
                        }}
                        flexItem
                      />
                    )}
                  </Stack>
                </Grid>
              );
            })}

            {/* <Grid item xs={12} sm={6} md={3}>
              {" "}
              <Stack
                direction={{ md: "row" }}
                justifyContent="center"
                alignItems={"center"}
                display={{ md: "flex" }}
              >
                <Stack>
                  <Typography
                    color={"white"}
                    sx={{ typography: "textLgSemi" }}
                    px={"15px"}
                  >
                    Maruti Suzuki ARENA
                    <br></br>
                    <Stack mt={2} direction={"row"}>
                      <Link target={"_blank"} href="">
                        <LocationOnIcon
                          sx={{
                            color: "primary.gray300",
                            cursor: "pointer",
                            width: "20px",
                          }}
                        />
                      </Link>
                      <Link target={"_blank"} href="">
                        <FacebookIcon
                          sx={{
                            color: "primary.gray300",
                            mx: "5px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Link>
                    </Stack>
                  </Typography>
                  <Typography
                    maxWidth={{ xs: "90%", md: "100%" }}
                    color={"white"}
                    sx={{ typography: "textXsReg" }}
                    py="5px"
                    px={"15px"}
                  >
                    SSG Majesty Mall, Plot No. 2 Road No. 43, Guru Harkishan
                    Marg, Pitam Pura, New Delhi, Delhi 110034 TEL : 011-40777444
                  </Typography>
                </Stack>
                <Divider
                  orientation="vertical"
                  sx={{
                    "&.MuiDivider-root": {
                      borderColor: "white",
                    },
                    minHeight: { md: "125.73px" },
                  }}
                  flexItem
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Stack
                direction={{ md: "row" }}
                justifyContent="center"
                alignItems={"center"}
                display={{ md: "flex" }}
              >
                <Stack>
                  {" "}
                  <Typography
                    color={"white"}
                    sx={{ typography: "textLgSemi" }}
                    px={"15px"}
                  >
                    NEXA
                    <br></br>
                    <Stack mt={2} direction={"row"}>
                      <Link target={"_blank"} href="https://goo.gl/maps/VQAnJVJm5tRbTgRm88">
                        <LocationOnIcon
                          sx={{
                            color: "primary.gray300",
                            cursor: "pointer",
                            width: "20px",
                          }}
                        />
                      </Link>
                      <Link target={"_blank"} href="www.facebook.com/NexaShreyashAutomotivesmayapuridelhi/">
                        <FacebookIcon
                          sx={{
                            color: "primary.gray300",
                            mx: "5px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Link>
                    </Stack>
                  </Typography>
                  <Typography
                    maxWidth={{ xs: "90%", md: "100%" }}
                    color={"white"}
                    sx={{ typography: "textXsReg" }}
                    py="5px"
                    px={"15px"}
                  >
                    A-23, Satguru Ram Singh Rd, Mayapuri Industrial Area Phase
                    Il, Mayapuri, New Delhi, Delhi 110064 MOB.: 9999673652
                  </Typography>
                </Stack>
                <Divider
                  orientation="vertical"
                  sx={{
                    "&.MuiDivider-root": {
                      borderColor: "white",
                    },
                    minHeight: { md: "125.73px" },
                  }}
                  flexItem
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Stack
                direction={{ md: "row" }}
                justifyContent="center"
                alignItems={"center"}
                display={{ md: "flex" }}
              >
                <Stack>
                  <Typography
                    color={"white"}
                    sx={{ typography: "textLgSemi" }}
                    px={"15px"}
                  >
                    NEXA
                    <br></br>
                    <Stack mt={2} direction={"row"}>
                      <Link target={"_blank"} href="https://goo.gl/maps/kjLsAyoRr4wXWKs19">
                        <LocationOnIcon
                          sx={{
                            color: "primary.gray300",
                            cursor: "pointer",
                            width: "20px",
                          }}
                        />
                      </Link>
                      <Link target={"_blank"} href="www.facebook.com/NexaShreyashAutomotivesPitampuraDelhi/">
                        <FacebookIcon
                          sx={{
                            color: "primary.gray300",
                            mx: "5px",
                            width: "20px",
                            cursor: "pointer",
                          }}
                        />
                      </Link>
                    </Stack>
                  </Typography>
                  <Typography
                    maxWidth={{ xs: "90%", md: "100%" }}
                    color={"white"}
                    sx={{ typography: "textXsReg" }}
                    py="5px"
                    px={"15px"}
                  >
                    SSG Majesty Mall, Plot no.1, Road No.43, Guru Harkishan
                    Marg, Pitam Pura, Delhi, 110002 MOB.: 7838555047
                  </Typography>
                </Stack>
                <Divider
                  orientation="vertical"
                  sx={{
                    "&.MuiDivider-root": {
                      borderColor: "white",
                    },
                    visibility: "hidden",
                    minHeight: { md: "125.73px" },
                  }}
                  flexItem
                />
              </Stack>
            </Grid> */}
          </Grid>
        </Stack>
        <Divider
          sx={{
            "&.MuiDivider-root": {
              borderColor: "white",
            },
          }}
        />
        <Stack
          display={"flex"}
          py="1.5%"
          direction={"row"}
          justifyContent="space-between"
        >
          <Typography
            display={"inline-block"}
            px={6}
            sx={{
              color: "primary.gray300",
              typography: { xs: "textSmReg", md: "textMdReg" },
            }}
          >
            © 2023 Shreyash Automotives Pvt. Ltd.. All rights reserved |{" "}
            <Typography
              onClick={() => {
                navigate("/privacy-policy");
              }}
              display={"inline-block"}
              sx={{
                color: "primary.gray300",
                typography: { xs: "textSmReg", md: "textMdReg" },

                textDecorationLine: "underline",
              }}
            >
              Pivacy Policy
            </Typography>
          </Typography>
        </Stack>
      </Stack>
      <GoToTop />
    </>
  );
}
