import { Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import Lottie from "react-lottie";
import SuccessAnimation from "../assets/images/success.json";
import FailedAnimation from "../assets/images/failed.json";

export default function Response(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData:
      props.response.type === "success" ? SuccessAnimation : FailedAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const handleBack = () => {
    props.setIsResponse({
      status: false,
      type: "",
      message: "",
      subText: "",
      buttonText: "",
    });
  };
  return (
    <Stack
      display={"flex"}
      my={10}
      justifyContent={"center"}
      alignItems="center"
    >
      <Box maxWidth={{ xs: "15%", md: "5%" }}>
        <Lottie options={defaultOptions} />
      </Box>
      <Typography
        my={2}
        mt={0}
        mx={3}
        textAlign={"center"}
        sx={{ typography: "textXlSemi", color: "primary.gray800" }}
      >
        {props.response.message}
      </Typography>
      <Typography
        my={2}
        mt={0}
        mx={3}
        maxWidth={{ xs: "75%", md: "20%" }}
        textAlign={"center"}
        sx={{ typography: "textLgReg", color: "primary.gray600" }}
      >
        {props.response.subText}
      </Typography>

      <Button
        size="small"
        variant="contain"
        sx={[
          {
            color: "primary.white",
            backgroundColor: "primary.black",
            my: "10px",
            ml: "10px",
            "&.MuiButton-root": {
              border: "1px solid",
              borderColor: "black",
              borderRadius: "0px",
            },
          },
          {
            "&:hover": {
              color: "primary.black",
              backgroundColor: "primary.white",
            },
          },
        ]}
        onClick={handleBack}
      >
        {props.response.buttonText}
      </Button>
    </Stack>
  );
}
