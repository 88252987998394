import { IconButton, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { lazy, Suspense, useCallback, useEffect, useRef } from "react";
// import Carousel from "react-material-ui-carousel";
// import fronxpdf from "../assets/FRONX.pdf";
import Banner from "../Components/Banner";
import HoverCarousel from "../Components/HoverCarousel";
import HoverPlay from "../Components/HoverPlay";
import PdfCarousel from "../Components/PdfCarousel";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import { useIsVisible } from "../Components/IsVisible";
const BannerImage = lazy(() => import("../Components/BannerImage"));

function CarPage(props) {
  // const [scroll, setScroll] = useState(0);
  const myRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (myRef.current) {
      if (window.scrollY >= 850) {
        props.setNav2(true);
      } else {
        props.setNav2(false);
      }
    }
  }, [props]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  const source = `${process.env.REACT_APP_BACKEND_URL}videos/fronx.mp4`;
  return (
    <div ref={myRef} onScroll={handleScroll()}>
      <Suspense
        fallback={
          <Skeleton sx={{ mx: "20px" }} variant="rectangular" height={430} />
        }
      >
        <BannerImage value={source} type={"video"} />
      </Suspense>

      {/* <Stack my={"3%"}>
        <embed src={fronxpdf} height={"500px"}></embed>
      </Stack> */}
      <Stack my={-1}>
        <Banner />
      </Stack>

      <Stack
        bgcolor={"black"}
        justifyContent={"center"}
        spacing={2}
        display={"flex"}
      >
        <Stack>
          <Typography
            mt={5}
            textAlign={"center"}
            sx={{
              color: "white",
              typography: "displayMdSemi",
              textTransform: "uppercase",
            }}
          >
            Performance
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{
              color: "white",
              typography: "textLgSemi",
              textTransform: "uppercase",
            }}
            mb={3}
          >
            Born To Outperform, In Style
          </Typography>
        </Stack>

        <Stack px={3} py={8}>
          <Stack sx={{ display: { xs: "none", md: "initial" } }}>
            <HoverPlay />
          </Stack>
          <Stack sx={{ display: { xs: "initial", md: "none" } }}>
            <HoverCarousel />
          </Stack>
        </Stack>
      </Stack>

      <Stack bgcolor={"black"}>
        <PdfCarousel />
        <Stack my={3} textAlign={"center"}>
          <a
            href={`${process.env.REACT_APP_BACKEND_URL}brochures/nexa/fronx.pdf`}
            download={true}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              my={3}
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
              color={"primary"}
              sx={{
                textTransform: "uppercase",
                typography: "textLgSemi",
                borderRadius: "0px",
                ":hover": { bgcolor: "white", color: "black" },
              }}
            >
              Download Brochure{" "}
              <DownloadForOfflineIcon
                sx={{ verticalAlign: "center", ml: "10px" }}
                fontSize="large"
              />
            </IconButton>
          </a>
        </Stack>
      </Stack>
    </div>
  );
}

export default CarPage;
