import React from "react";
import mountain from "../assets/images/Fronx 1.webp";
import fronx from "../assets/images/Bannerfronx.webp";
import "../assets/css/Banner.css";
import { Box, Stack, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const Banner = () => {
  return (
    <>
      <Stack
        sx={{
          background:
            " linear-gradient(0deg, rgb(0,0,0), rgba(17,17,17,0.9)),url(https://freepik.cdnpk.net/assets/964f58b6a12e1b2e627e8d505958023b.png)",
        }}
      >
        <Typography
          my={10}
          textAlign={"center"}
          sx={{
            color: "white",
            typography: { xs: "textLgSemi", md: "displayMdSemi" },
            textTransform: "uppercase",

            position: "sticky",
            top: "8rem",

            transition: "ease-in-out 1s top",
          }}
        >
          WITNESS THE NEW FACE OF SUVs <br></br>
          <br></br>
          <Typography
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              color: "white",
              typography: { xs: "textMdSemi", md: "textXlSemi" },
              textTransform: "uppercase",
            }}
          >
            <CalendarMonthIcon sx={{ mx: "5px" }} /> 09th & 10th March, 2023{" "}
            <LocationOnIcon sx={{ mx: "5px" }} /> Shreyash Mayapuri
          </Typography>
        </Typography>
        <Box
          component={"img"}
          src={fronx}
          width={{ xs: "50%", md: "30%" }}
          display={"flex"}
          my={3}
          justifySelf="center"
          alignSelf={"center"}
          sx={{
            position: "sticky",
            top: "20rem",
            transition: "ease-in-out 1s top",
          }}
        />
        {/* <Typography
          my={3}
          textAlign={"center"}
          sx={{
            color: "white",
            typography: {xs:"textLgSemi",md:"displayMdSemi"},
            textTransform: "uppercase",

            position: "sticky",
            top: {xs:"32rem",md:"40px"},

            transition: "ease-in-out 1s top",
          }}
        >
          WITNESS THE NEW FACE OF SUVs{" "}
         <CalendarMonthIcon /> {" "} 09th & 10th March, 2023 {" "} <LocationOnIcon sx={{ml:"10px"}} /> {" "}
Shreyash Mayapuri


        </Typography> */}
        <Box component={"img"} src={mountain} pt={"5%"} sx={{ zIndex: "10" }} />
      </Stack>
    </>
  );
};

export default Banner;
