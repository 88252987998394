import styled from "@emotion/styled";
import {
  AppBar,
  Button,
  Divider,
  FormControl,
  IconButton,
  // InputLabel,
  List,
  ListItem,
  MenuItem,
  // Select,
  SwipeableDrawer,
  TextField,
  Toolbar,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import suzuki from "../assets/svgs/suzuki.svg";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "../assets/css/Navbar.module.css";

const MyToolbar = styled(Toolbar)({
  display: "flex",
  padding: "12px 10px",
  justifyContent: "space-between",
});

const buttonStyle = [
  {
    color: "primary.gray600",
    fontSize: "16px",
    fontWeight: "600",
    padding: "12px 20px",
    lineHeight: "24px",
  },
  {
    "&:hover": {
      bgcolor: "primary.gray100",
      color: "primary.black",
      cursor: "pointer",
    },
    // "&:focus": {
    //   borderBottom: 2,
    // },
  },
];

export default function Navbar(props) {
  const [location, setLocation] = React.useState("");
  const [open, setOpen] = useState(false);
  const handleChange = (event) => {
    setLocation(event.target.value);
  };
  const navigate = useNavigate();
  return (
    <>
      <Stack
        display={"flex"}
        justifyContent={{ xs: "center", md: "end" }}
        alignItems={"center"}
        spacing={"5%"}
        mx="5%"
        py={"0.5%"}
        direction="row"
      >
        <Box sx={{ minWidth: "100px" }}>
          <FormControl fullWidth variant="standard">
            <TextField
              select
              size="small"
              labelid="demo-simple-select-label"
              id="demo-simple-select"
              value={location}
              label={location === "" ? "Delhi" : ""}
              InputLabelProps={{ shrink: false }}
              sx={{
                verticalAlign: "center",
                maxHeight: "30px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              color="secondary"
              onChange={handleChange}
            >
              <MenuItem value={" Pitam Pura - Arena"}>
                Pitam Pura - Arena
              </MenuItem>
              <MenuItem value={"Pitam Pura - Nexa"}>Pitam Pura - Nexa</MenuItem>
              <MenuItem value={"Jahangirpuri"}>Jahangirpuri</MenuItem>
              <MenuItem value={"Mayapuri"}>Mayapuri</MenuItem>
            </TextField>
          </FormControl>
        </Box>
        <Box>
          <img
            src={suzuki}
            alt="suzuki logo"
            style={{ width: "200px", verticalAlign: "middle" }}
          />
        </Box>
      </Stack>

      <AppBar
        position="sticky"
        sx={{
          boxShadow: "none",
          px: "3%",
          backgroundColor: "primary.main",
        }}
      >
        <MyToolbar>
          <Stack
            display={"flex"}
            justifyContent="center"
            alignItems={"center"}
            spacing={"5%"}
            direction="row"
            alignSelf="center"
          >
            <NavLink
              style={{ textDecoration: "none" }}
              to="/"
              // className={({ isActive }) =>
              //   isActive ? classes.active : classes.inactive
              // }
            >
              <Box>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: "163px", verticalAlign: "middle" }}
                />
              </Box>
            </NavLink>
          </Stack>
          {props.nav2 ? (
            <Stack
              display={{ xs: "none", md: "flex" }}
              spacing="10px"
              direction="row"
              alignSelf="center"
            >
              <Box
                component={"img"}
                mx={2}
                src="https://nexaprod1.azureedge.net/-/media/feature/nexawebsitecarbrand/flash/logo/logo-125x35-webp.webp?modified=20230116083254&la=en&hash=C95699DABD36825CDA0B9C6A5DD9DFAB"
              />
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}brochures/nexa/fronx.pdf`}
                download={true}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  disableRipple
                  size="small"
                  variant="contain"
                  sx={[
                    {
                      color: "primary.white",
                      backgroundColor: "primary.black",
                      my: "10px",
                      ml: "10px",
                      "&.MuiButton-root": {
                        border: "1px solid",
                        borderColor: "black",
                        borderRadius: "0px",
                      },
                    },
                    {
                      "&:hover": {
                        color: "primary.black",
                        backgroundColor: "primary.white",
                      },
                    },
                  ]}
                  // onClick={() =>
                  //   props.navbarRef.current.scrollIntoView({
                  //     behavior: "smooth",
                  //     block: "start",
                  //   })
                  // }
                >
                  Download Brochure
                </Button>
              </a>
            </Stack>
          ) : (
            <Stack
              display={{ xs: "none", md: "flex" }}
              spacing="10px"
              direction="row"
              alignSelf="center"
            >
              <NavLink
                style={{ textDecoration: "none" }}
                to="/about-us"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  About
                </Button>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/arena"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  Arena
                </Button>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/nexa"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  Nexa
                </Button>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/true-value"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  True Value
                </Button>
              </NavLink>
              <NavLink
                style={{ textDecoration: "none" }}
                to="/services"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  Service Center
                </Button>
              </NavLink>
              {/* <NavLink
                style={{ textDecoration: "none" }}
                to="/driving-school"
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <Button disableRipple size="medium" sx={buttonStyle}>
                  Driving School
                </Button>
              </NavLink> */}
            </Stack>
          )}
          <Stack
            display={{ xs: "none", md: "flex" }}
            spacing="10px"
            direction="row"
            alignSelf="center"
          >
            <a href="tel:+919811876876" style={{ textDecoration: "none" }}>
              <Button disableRipple variant="text" sx={buttonStyle}>
                +91 9811 876 876
              </Button>
            </a>
            <Button
              disableRipple
              size="small"
              variant="contain"
              sx={[
                {
                  color: "primary.white",
                  backgroundColor: "primary.black",
                  my: "10px",
                  ml: "10px",
                  "&.MuiButton-root": {
                    border: "1px solid",
                    borderColor: "black",
                    borderRadius: "0px",
                  },
                },
                {
                  "&:hover": {
                    color: "primary.black",
                    backgroundColor: "primary.white",
                  },
                },
              ]}
              onClick={() =>
                props.navbarRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                })
              }
            >
              Book Now!
            </Button>
          </Stack>

          {/* Mobile View */}

          <Stack sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
          </Stack>
        </MyToolbar>
        <SwipeableDrawer
          PaperProps={{ sx: { backgroundColor: "primary.main" } }}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          anchor="right"
        >
          <div>
            <IconButton onClick={() => setOpen(!open)}>
              {" "}
              <ArrowForwardIosIcon />{" "}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              sx={buttonStyle}
              onClick={() => {
                navigate("/about-us");
              }}
            >
              About
            </ListItem>
            <ListItem
              sx={buttonStyle}
              onClick={() => {
                navigate("/arena");
              }}
            >
              Arena
            </ListItem>
            <ListItem
              sx={buttonStyle}
              onClick={() => {
                navigate("/nexa");
              }}
            >
              Nexa
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("/true-value");
              }}
              sx={buttonStyle}
            >
              {" "}
              True Value
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("/services");
              }}
              sx={buttonStyle}
            >
              {" "}
              Service center
            </ListItem>
            <ListItem
              onClick={() => {
                navigate("/driving-school");
              }}
              sx={buttonStyle}
            >
              {" "}
              Driving School
            </ListItem>
            <ListItem sx={buttonStyle}>
              {" "}
              <a
                href="tel:+919811876876"
                style={{ textDecoration: "none", color: "black" }}
              >
                +91 9811 876 876
              </a>
            </ListItem>
            <ListItem sx={{ justifyContent: "start" }}>
              <Button
                disableRipple
                size="small"
                variant="contain"
                sx={[
                  {
                    color: "primary.white",
                    backgroundColor: "primary.black",
                    my: "10px",
                    ml: "10px",
                    "&.MuiButton-root": {
                      border: "1px solid",
                      borderColor: "black",
                      borderRadius: "0px",
                    },
                  },
                  {
                    "&:hover": {
                      color: "primary.black",
                      backgroundColor: "primary.white",
                    },
                  },
                ]}
              >
                Book Now!
              </Button>
            </ListItem>
          </List>
        </SwipeableDrawer>
      </AppBar>
    </>
  );
}
