import { Box, Divider, Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/use-api";
import Card from "../Card/Card";
import arena from "../../assets/images/arena.png";

export default function CardSection(props) {
  const [carCategories, setCarCategories] = useState([]);

  // ? *************************************************** All the car categories *************************************************** */
  const categories = async () => {
    try {
      const result = await useApi.getRequest("car-categories", false);
      if (result.status) {
        return setCarCategories(result.data);
      }

      throw new Error(result.message);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    categories();
  }, []);

  let content = "";

  if (Object.keys(carCategories).length === 0) {
    content = "Loading...";
  }

  if (Object.keys(carCategories).length > 0) {
    content = (
      <Stack mx={"2%"} display="flex" maxWidth="lg">
        <Stack
          mx={"3%"}
          display={"flex"}
          justifyContent={{ xs: "center", md: "normal" }}
          alignItems={{ xs: "center", md: "normal" }}
          my="2%"
        >
          <Stack
            display={"flex"}
            justifyContent={{ xs: "center", md: "normal" }}
            alignItems={{ xs: "center", md: "normal" }}
            maxWidth="lg"
          >
            {carCategories.map((category) => (
              <Card
                key={category.id}
                imageType={arena}
                heading={category.name}
                type="arena"
                cars={category.car}
                bookNowRef={props.bookNowRef}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <>
      {Object.keys(carCategories).length > 0 ? (
        <Box component={"img"} src={arena} maxWidth={"100%"} />
      ) : (
        <Skeleton
          sx={{ mx: "20px" }}
          variant="rectangular"
          width={"100vw"}
          height={790}
        />
      )}

      <Stack display={"flex"} justifyContent={"center"} alignItems="center">
        {content}
      </Stack>

      <Divider variant="middle" />
    </>
  );
}
