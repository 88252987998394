import React from "react";
import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import arena from "../assets/images/ArenaCard.webp";
import trueval from "../assets/images/truevalueCard.webp";
import service from "../assets/images/ServiceCard.webp";
// import drive from "../assets/images/DrivingCard.webp";
import nexa from "../assets/images/NexaCard.webp";
import { useNavigate } from "react-router-dom";
const carouselImg = [
  {
    imgSrc: arena,
    title: "Arena",
    route: "/arena",
    supportText: "Engineered for efficiency and comfort. ",
  },
  {
    imgSrc: nexa,
    title: "Nexa",
    route: "/nexa",
    supportText: "The ultimate definition of performance.",
  },
  {
    imgSrc: trueval,
    title: "True Value",
    route: "/true-value",
    supportText: "A hassle-free way to buy & sell used cars.",
  },
  {
    imgSrc: service,
    title: "Service Center",
    route: "/services",
    supportText: "Redefining reliability and care.  ",
  },
  // {
  //   imgSrc: drive,
  //   title: "Driving School",
  //   route: "/driving-school",
  //   supportText: "Get ready to start a new journey. ",
  // },
];
export default function HomeHoverPlay() {
  const navigate = useNavigate();
  return (
    <Stack
      display={"flex"}
      mx={3}
      rowGap={3}
      direction={"row"}
      flexWrap="wrap"
      justifyContent={"center"}
      alignItems="center"
      columnGap={3}
    >
      {carouselImg.map((data) => {
        return (
          <Stack
            sx={{ cursor: "pointer", overflow: "hidden" }}
            onClick={() => {
              navigate(data.route);
            }}
          >
            <Box
              component={"img"}
              maxWidth={{ xs: "330px", md: "500px" }}
              // minWidth="330px"
              borderRadius={3}
              src={data.imgSrc}
              sx={[
                { transition: "all .5s ease-in-out" },
                { "&:hover": { transform: "scale(0.98)" } },
              ]}
              my={2}
            />
            <Typography
              sx={{
                typography: "textLgReg",
                textTransform: "uppercase",
                color: "white",
              }}
            >
              {data.title}
            </Typography>
            <Typography
              sx={{
                typography: "textMdReg",
                color: "white",
              }}
            >
              {data.supportText}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
