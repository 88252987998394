import * as yup from "yup";
import "yup-phone";

export const buySchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  mobile: yup
    .string()
    .phone(null, true, "Invalid Number")
    .required("Your mobile number is required"),
  name: yup
    .string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your full name."),
  car_id: yup.string().required("Please select the car model."),
  disclaimer: yup
    .bool()
    .oneOf([true], "You must accept the terms and conditions"),
});
