import { Skeleton, Stack } from "@mui/material";
import React, { useState, useRef, lazy, Suspense } from "react";
import { useFormik } from "formik";
import "yup-phone";
import * as yup from "yup";
import useApi from "../../hooks/use-api";
import Service from "../Forms/Service";
import Response from "../../Utils/Response";
const BannerImage = lazy(() => import("../BannerImage"));

export default function Services() {
  const captchaRef = useRef(null);
  const [isResponse, setIsResponse] = useState({
    status: false,
    type: "",
    message: "",
    subText: "",
    buttonText: "",
  });

  const validationSchema = yup.object({
    mobile: yup
      .string()
      .phone(null, true, "Invalid Number")
      .required("Your mobile number is required"),
    name: yup
      .string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your full name."),

    disclaimer: yup
      .bool()
      .oneOf([true], "You must accept the terms and conditions"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      disclaimer: false,
      checkField: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await useApi.postRequest(`services`, false, values);
        if (result.status) {
          captchaRef.current.reset();
          formik.resetForm();
          return setIsResponse({
            status: true,
            type: "success",
            message: result.message,
            subText:
              "Thank you for sharing your details. We will be in touch soon.",
            buttonText: "Back",
          });
        }
        throw new Error(result.message);
      } catch (error) {
        setIsResponse({
          status: true,
          type: "failed",
          message: error.message,
          subText: "Please Try Again.",
          buttonText: "Try Again",
        });
      }
    },
  });

  return (
    <Stack>
      <Suspense
        fallback={
          <Skeleton sx={{ mx: "20px" }} variant="rectangular" height={430} />
        }
      >
        <BannerImage
          value={`${process.env.REACT_APP_BACKEND_URL}videos/technology.mov`}
          type={"video"}
        />
      </Suspense>
      {!isResponse.status && (
        <Service formik={formik} captchaRef={captchaRef} />
      )}
      {isResponse.status && (
        <Response response={isResponse} setIsResponse={setIsResponse} />
      )}
    </Stack>
  );
}
